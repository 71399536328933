import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  TagLabel,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { BiIdCard } from "react-icons/bi";
import { PiPasswordBold } from "react-icons/pi";
// import { PiPassword } from "react-icons/pi";
import { RiUserShared2Fill } from "react-icons/ri";
import logo from "../../Assets/BoomLogo.png";
import { Field, Form, Formik } from "formik";
import { EmailIcon, LockIcon, PhoneIcon } from "@chakra-ui/icons";
import { authInstance } from "../../Axios/Axios";
import axios from "axios";
import AuthToast from "../Toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoginContext from "../../Context/Login/LoginContext";
import { useCookies } from "react-cookie";

type Props = {};
interface initialValuesSignUp {
  name: string;
  email: string;
  mobile: string;
  pancard: string;
  password: string;
  confirmPassword?: string; // Mark confirmPassword as optional using the ?
  referby: string;
  otp: string;
  gender: any;
}
interface initialValuesLogin {
  email: string;
  password: string;
}
interface initialValuesForgotPassword {
  email: string;
}
interface initialOtpForgotPassword {
  email: string;
  otp: string;
  newPassword: string;
  confirmNewPassword?: string;
}
const AuthTemplate = (props: Props) => {
  let { loginState, setLoginState, currentUser, cartCount } =
    useContext(LoginContext);
  // console.log(loginState);
  const [isLogin, setIsLogin] = useState(true);
  const [forgotEmailError, setForgotEmailError] = useState(false);
  const [signupButtonText, setSignupButtonText] = useState("SignUp");
  const [forgotEmailErrorText, setForgotEmailErrorText] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");
  const [pancardError, setpancardError] = useState(false);
  const [pancardErrorText, setPancardErrorText] = useState("");
  const [refercodeError, setRefercodeError] = useState(false);
  const [refercodeErrorText, setRefercodeErrorText] = useState("");
  const [loginEmailError, setLoginEmailError] = useState(false);
  const [loginEmailErrorText, setLoginEmailErrorText] = useState("");
  const [loginPasswordError, setLoginPasswordError] = useState(false);
  const [loginPasswordErrorText, setLoginPasswordErrorText] = useState("");
  const [signupDisabled, setSignupDisabled] = useState(false);
  const [loginDisabled, setLoginDisabled] = useState(false);
  const [loginButtonText, setLoginButtonText] = useState("Login");
  const [sendOtpButton, setSendOtpButton] = useState(false);
  const [sendOtpButtonText, setSendOtpButtonText] = useState("Verify Email");
  const [newPasswordText, setNewPasswordText] = useState("Set New Password");
  const [newPasswordButton, setNewPasswordButton] = useState(true);

  const [verifyOtpError, setVerifyOtpError] = useState(false);
  const [verifyOtpErrorText, setVerifyOtpErrorText] = useState("");
  const [confirmNewPasswordText, setConfirmNewPasswordText] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [numberOfAttempts, setNumberOfAttempts] = useState(1);
  const navigate = useNavigate();
  const toast = useToast();
  const { source } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ref = searchParams.get("ref");
  const {
    isOpen: otpOpen,
    onOpen: otpOnOpen,
    onClose: otpOnClose,
  } = useDisclosure();
  const {
    isOpen: pancardOpen,
    onOpen: pancardOnOpen,
    onClose: pancardOnClose,
  } = useDisclosure();
  const {
    isOpen: forgotOpen,
    onOpen: forgotOnOpen,
    onClose: forgotOnClose,
  } = useDisclosure();
  const {
    isOpen: verifyOtpOpen,
    onOpen: verifyOtpOnOpen,
    onClose: verifyOtpOnClose,
  } = useDisclosure();
  const [cookies, setCookie, removeCookie] = useCookies();
  useEffect(() => {
    // pancardOnOpen();
  }, []);
  const signUp = async (values: initialValuesSignUp) => {
    // delete values.confirmPassword;
    setSignupDisabled(true);
    if (signupButtonText === "Verify OTP") {
      const { confirmPassword, ...restValues } = values;
      // values.password = new Login(values.password);
      // console.log(values.password);
      // console.log(values);
      try {
        // values = JSON.stringify(values);
        let res = await authInstance.post("/register", restValues, {});
        if (res.status === 200) {
          AuthToast(``, toast, "SignUp Successfull.", "success");
          login({ email: values.email, password: values.password });
        }
        // otpOnOpen();
        // console.log(res.data);
      } catch (error) {
        // console.log(error);
        if (
          (error as any).response &&
          (error as any).response.data.message === "You are already registered!"
        ) {
          setEmailError(true);
          setEmailErrorText("Email is already registered");
        } else if (
          (error as any).response &&
          (error as any).response.data.message ===
            "Please try with another Pancard!"
        ) {
          // console.log("Invalid Pancard");
          setpancardError(true);
          setPancardErrorText(
            "You can only create 3 Account with a Pancard, Please try with another Pancard"
          );
        } else if (
          (error as any).response &&
          (error as any).response.data.message === "Invalid referral ID!"
        ) {
          // console.log("Invalid Refer Code");
          setRefercodeError(true);
          setRefercodeErrorText("Invalid Reffer Code, Try again!");
        } else if (
          (error as any).response &&
          (error as any).response.data.message === "Invalid or Expired OTP!"
        ) {
          // console.log("Invalid OTP");
          setVerifyOtpError(true);
          setVerifyOtpErrorText(`Wrong OTP`);
        }
        // Invalid referral ID!
      }
      setSignupDisabled(false);
    } else if (signupButtonText === "SignUp") {
      setSignupButtonText("Loading...");
      const { email, ...restValues } = values;
      // console.log(email);
      let res = await authInstance.post("/pre_register", { email }, {});
      // console.log(res.data);
      AuthToast(``, toast, `OTP Sent to ${email}.`, "success");
      setSignupButtonText("Verify OTP");
      setSignupDisabled(false);
    }
  };
  // Usage
  // const password = new Login("mySecretPassword");

  // console.log(password.login("mySecretPassword")); // Output: false
  // console.log(password.login("mySecretPassword")); // Output: true
  // function ToastExample() {

  //   return (
  //     <Button
  //       onClick={() =>
  //         toast({
  //           title: "Account created.",
  //           description: "We've created your account for you.",
  //           status: "success",
  //           duration: 2000,
  //           isClosable: true,
  //         })
  //       }
  //     >
  //       Show Toast
  //     </Button>
  //   );
  // }
  class user {
    email: string;
    #password: any;
    constructor(username: string, password: string) {
      this.email = username;
      this.#password = password;
    }
  }
  const login = async (values: initialValuesLogin) => {
    // console.log(values);
    let newUser = new user(values.email, values.password);
    // console.log(newUser);
    setLoginEmailError(false);
    setLoginEmailErrorText("");
    setLoginPasswordError(false);
    setLoginPasswordErrorText("");
    setLoginDisabled(true);
    setLoginButtonText("Loading...");
    let userId: number;
    try {
      const res = await authInstance.post("/login", values);
      // console.log(res.data);
      if (res.data.message === "Login Successfully") {
        const token = res.data.token;
        setCookie("token", token, { path: "/" });
        try {
          let res = await authInstance.post(`/verify-token`, { token: token });
          if (res.status === 200 && res.data.success === true) {
            cartCount = res.data.cartCount;
            currentUser = res.data.Data[0];
            if (source) {
              navigate(`/${source}/id=${currentUser.id}`);
            }
            AuthToast(
              `Welcome ${values.email}`,
              toast,
              "Login Successfull.",
              "success"
            );
            return true;
          } else {
            removeCookie("token");
            setLoginState(false);
            return false;
          }
        } catch (err) {
          removeCookie("token");
          setLoginState(false);
        }
        setLoginState(true);
        setLoginButtonText("Login");
        setLoginDisabled(false);
        // localStorage.setItem("Login", JSON.stringify(true));
        // navigate("/");

        // alert("Login");
      }
    } catch (error) {
      setLoginButtonText("Login");
      setLoginDisabled(false);
      // console.log(error);

      if (
        (error as any)?.response?.status === 404 ||
        (error as any)?.response.data.message === "You don't have an account!"
      ) {
        setLoginEmailError(true);
        setLoginEmailErrorText("Email not found, Please SignUp");
      } else if (
        (error as any)?.response?.status === 400 ||
        (error as any)?.response.data.message === "Incorrect Password!"
      ) {
        setLoginPasswordError(true);
        setLoginPasswordErrorText("Wrong Password, Try Again!");
      }
      // (error as any)?.response?.status === 404
      //   ? (() => {
      //       setLoginEmailError(true);
      //       setLoginEmailErrorText("Email not found, Please SignUp");
      //     })()
      //   : "";
    }
  };
  const forgotPassword = async (values: initialValuesForgotPassword) => {
    // console.log(values);
    // sessionStorage.setItem("forgotEmail", JSON.stringify(values.email));
    // verifyOtpOnOpen();
    setSendOtpButton(true);
    setSendOtpButtonText("Loading...");
    try {
      const res = await authInstance.post("/forgot-password", values);
      // console.log(res);
      if (res.status === 200) {
        forgotOnClose();
        sessionStorage.setItem("forgotEmail", JSON.stringify(values.email));
        verifyOtpOnOpen();
        AuthToast(``, toast, `OTP Sent to ${values.email}`, "success");
      }
      setSendOtpButton(false);
      setSendOtpButtonText("Verify Email");
    } catch (err) {
      setSendOtpButton(false);
      setSendOtpButtonText("Verify Email");
      // console.log(err);
      if ((err as any)?.response?.data?.message === "Invalid email Id!") {
        setForgotEmailError(true);
        setForgotEmailErrorText("Invalid Email Id, Please SignUp First!");
      }
    }
  };
  // let i = 1;
  const verifyOTP = async (values: initialOtpForgotPassword) => {
    // delete values.confirmNewPassword;
    setNewPasswordButton(true);
    setNewPasswordText("Loading...");
    const { confirmNewPassword, ...restValues } = values;
    // console.log(values);
    try {
      const res = await authInstance.post("/verify/otp", restValues);
      // console.log(res);
      setNewPasswordButton(false);
      setNewPasswordText("Set New Password");
      sessionStorage.removeItem("forgotEmail");
      verifyOtpOnClose();
      AuthToast(``, toast, "Password Reset Successfully!", "success");
      if (res.status === 409) {
        AuthToast(
          "",
          toast,
          "You cannot replace the current password",
          "warning"
        );
      }
    } catch (err) {
      // console.log(err);
      setNumberOfAttempts(numberOfAttempts + 1);
      if (
        (err as any)?.response?.data?.message === "Invalid email Id or OTP!"
      ) {
        setVerifyOtpError(true);
        setVerifyOtpErrorText(`Wrong OTP`);
        // console.log(numberOfAttempts);
        if (numberOfAttempts < 3) {
          // Incorrect OTP, ${i} attempt used, only ${3 - i} attempt left
          AuthToast(
            `Incorrect OTP, ${numberOfAttempts} attempt used, only ${
              3 - numberOfAttempts
            } attempt left`,
            toast,
            "Wrong OTP",
            "warning"
          );
        }
        if (numberOfAttempts === 3) {
          sessionStorage.removeItem("forgotEmail");
          verifyOtpOnClose();
          AuthToast(
            `You tried all three attempts`,
            toast,
            "Out Of Attempts",
            "error"
          );
        }
      }
      if (
        (err as any)?.response?.data?.message ===
        "You can not replace with same password!"
      ) {
        // console.log("hey");
        // setVerifyOtpError(true);
        // setVerifyOtpErrorText(`Wrong OTP`);
        // console.log(numberOfAttempts);
        if (numberOfAttempts < 3) {
          // Incorrect OTP, ${i} attempt used, only ${3 - i} attempt left
          AuthToast(
            `You cannot use new password as current password`,
            toast,
            "Use Different Password",
            "warning"
          );
        }
        // if (numberOfAttempts === 3) {
        //   sessionStorage.removeItem("forgotEmail");
        //   verifyOtpOnClose();
        //   AuthToast(
        //     `You tried all three attempts`,
        //     toast,
        //     "Out Of Attempts",
        //     "error"
        //   );
        // }
      }
      setNewPasswordButton(false);
      setNewPasswordText("Set New Password");
    }
  };
  const verifySignUpOtp = async (values: any) => {
    // console.log(values);
    // console.log("Verifying OTP");
  };
  const initialValuesSignUp: initialValuesSignUp = {
    name: "",
    email: "",
    mobile: "",
    pancard: "",
    password: "",
    confirmPassword: "",
    referby: ref ? ref : "",
    otp: "",
    gender: "any",
  };

  const initialValuesLogin: initialValuesLogin = {
    email: "",
    password: "",
  };

  const initialValuesForgotPassword: initialValuesForgotPassword = {
    email: "",
  };
  const initialValuesSignUpOtp: any = {
    otp: "",
    pancard: "",
  };

  const initialOtpForgotPassword: initialOtpForgotPassword = {
    email: "",
    otp: "",
    newPassword: "",
    confirmNewPassword: "",
  };
  const initialAddPancard: any = {};
  const forgotEmail = sessionStorage.getItem("forgotEmail");
  if (forgotEmail !== null) {
    initialOtpForgotPassword.email = JSON.parse(forgotEmail);
  }
  return (
    <Box w={"100%"} h={"100%"}>
      <HStack w={"100%"} h={"100%"} bg={"rgba(152, 251, 152, 0.94);"}>
        <Box
          h={"100%"}
          w={{ base: "50%", md: "40%", lg: "30%" }}
          gap={0}
          display={{ base: "hidden", lg: "block" }}
        >
          <Box w={"100%"} bg={"white"} h={"100%"} borderRightRadius={"50%"}>
            <VStack h={"100%"} m={"auto"}>
              <Flex
                alignItems={"flex-end"}
                h={"50%"}
                boxSizing={"border-box"}
                // border={"1px solid red"}
                pb={8}
              >
                <Image src={logo} h={"25%"} w={"30%"} alt={"LogoImage"} />
                <Box
                  //   border={"1px solid red"}
                  color={"#707070"}
                  h={"20%"}
                  display={"flex"}
                  flexDir={"column"}
                  justifyContent={"space-around"}
                  ml={3}
                >
                  <Text
                    fontSize={"25px"}
                    as={"b"}
                    lineHeight={"25px"}
                    letterSpacing={"wider"}
                    // border={"1px solid red"}
                  >
                    ShoppingBoom
                  </Text>
                  <Text as={"b"} fontSize={"15px"}>
                    We Believe in Making Trust
                  </Text>
                </Box>
              </Flex>
              <Box
                // border={"1px solid red"}
                h={"40%"}
                w={"100%"}
                display={"flex"}
                flexDir={"column"}
              >
                <Button
                  bg={"rgba(112, 112, 112, 0.33)"}
                  //   color={"white"}
                  m={"auto"}
                  _hover={{ bg: "rgba(112, 112, 112, 0.50)" }}
                  onClick={() => {
                    setIsLogin(!isLogin);
                  }}
                >
                  {isLogin ? "SignUp" : "Login"}
                </Button>
              </Box>
            </VStack>
          </Box>
        </Box>
        <VStack h={"100%"} w={"70%"}>
          {isLogin ? (
            <VStack
              w={"100%"}
              h={"100%"}
              boxSizing={"border-box"}
              pt={40}
              color={"#707070"}
            >
              <Text as={"b"} fontSize={"40px"}>
                Login
              </Text>
              <Flex
                h={"fit-content"}
                w={"60%"}
                mt={10}
                // p={5}
                flexDir={"column"}
                gap={6}
                // border={"1px solid red"}
                mb={10}
              >
                <Formik
                  initialValues={initialValuesLogin}
                  onSubmit={(values, { setSubmitting }) => {
                    login(values);
                  }}
                >
                  <Form
                    style={{
                      // border: "1px solid red",
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                      padding: 5,
                      maxHeight: "100%",
                      height: "fit-content",
                      justifyContent: "space-around",
                    }}
                  >
                    <Flex
                      flexDir={"column"}
                      gap={4}
                      maxH={"95%"}
                      h={"fit-content"}
                      pb={3}
                      // overflowY={"auto"}
                      // border={"1px solid red"}
                    >
                      <Field name={"email"}>
                        {({ field, form }: { field: any; form: any }) => {
                          return (
                            <FormControl
                              display={"flex"}
                              flexDir={"column"}
                              isRequired
                            >
                              <FormLabel as={"b"} fontSize={"25px"}>
                                Email
                              </FormLabel>
                              <InputGroup>
                                <InputLeftElement pointerEvents="none">
                                  <EmailIcon />
                                </InputLeftElement>
                                <Input
                                  {...field}
                                  name={"email"}
                                  bg={"white"}
                                  placeholder={"abc@xyz.com"}
                                  type={"email"}
                                  color={loginEmailError ? "red" : "gray.600"}
                                  borderColor={
                                    loginEmailError ? "red" : "gray.300"
                                  }
                                  borderWidth={1}
                                  onFocus={() => {
                                    setLoginEmailError(false);
                                    setLoginEmailErrorText("");
                                  }}
                                />
                              </InputGroup>
                              {loginEmailError && (
                                <div style={{ color: "red" }}>
                                  {loginEmailErrorText}
                                </div>
                              )}
                            </FormControl>
                          );
                        }}
                      </Field>
                      <Field name="password">
                        {({ field, form }: { field: any; form: any }) => {
                          const { value, onBlur } = field;
                          const { touched, errors } = form;

                          return (
                            <FormControl
                              display="flex"
                              flexDir="column"
                              isRequired
                            >
                              <FormLabel as="b" fontSize="20px">
                                Password
                              </FormLabel>
                              <InputGroup>
                                <InputLeftElement>
                                  <LockIcon />
                                </InputLeftElement>
                                <Input
                                  {...field}
                                  bg="white"
                                  placeholder="Password"
                                  type="password"
                                  onBlur={onBlur}
                                  color={
                                    loginPasswordError ? "red" : "gray.600"
                                  }
                                  borderColor={
                                    loginPasswordError ? "red" : "gray.300"
                                  }
                                  onFocus={() => {
                                    setLoginPasswordError(false);
                                    setLoginPasswordErrorText("");
                                  }}
                                />
                              </InputGroup>
                              {loginPasswordError && (
                                <div style={{ color: "red" }}>
                                  {loginPasswordErrorText}
                                </div>
                              )}
                            </FormControl>
                          );
                        }}
                      </Field>
                    </Flex>
                    <Flex justifyContent={"center"}>
                      <Button type={"submit"} isDisabled={loginDisabled}>
                        {loginButtonText}
                      </Button>
                    </Flex>
                  </Form>
                </Formik>
                <Flex justifyContent={"center"}>
                  <Text
                    // border={"1px solid red"}
                    onClick={forgotOnOpen}
                    p={2}
                    textAlign={"center"}
                    as={"b"}
                    cursor={"pointer"}
                  >
                    Forgot Password ?
                  </Text>
                </Flex>
              </Flex>
            </VStack>
          ) : (
            <VStack
              w={"100%"}
              h={"100%"}
              boxSizing={"border-box"}
              pt={5}
              color={"#707070"}
              // border={"1px solid blue"}
              m={"auto"}
            >
              <Text
                as={"b"}
                position={"sticky"}
                top={10}
                fontSize={"40px"}
                // border={"1px solid red"}
              >
                SignUp
              </Text>
              <Stack
                h={"85%"}
                w={{ base: "100%", md: "90%", lg: "65%" }}
                mt={5}
                p={5}
                // border={"1px solid cyan"}
                boxSizing="border-box"
                justify={"space-around"}
                // overflowY={"auto"}
              >
                <Formik
                  initialValues={initialValuesSignUp}
                  onSubmit={(values, { setSubmitting }) => {
                    signUp(values);
                  }}
                >
                  <Form
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                      maxHeight: "100%",
                      height: "fit-content",
                      justifyContent: "space-around",
                    }}
                  >
                    <Flex
                      flexDir={"column"}
                      gap={4}
                      maxH={"95%"}
                      h={"fit-content"}
                      overflowY={"auto"}
                      // border={"1px solid red"}
                    >
                      <Field name={"email"}>
                        {({ field, form }: { field: any; form: any }) => {
                          return (
                            <FormControl
                              display={"flex"}
                              flexDir={"column"}
                              isRequired
                            >
                              <FormLabel as={"b"} fontSize={"20px"}>
                                Email
                              </FormLabel>
                              <InputGroup>
                                <InputLeftElement pointerEvents="none">
                                  <EmailIcon />
                                </InputLeftElement>
                                <Input
                                  {...field}
                                  name={"email"}
                                  isDisabled={
                                    signupButtonText === "Verify OTP"
                                      ? true
                                      : false
                                  }
                                  bg={"white"}
                                  placeholder={"abc@xyz.com"}
                                  type={"email"}
                                  color={emailError ? "red" : "gray.600"}
                                  borderColor={emailError ? "red" : "gray.300"}
                                  borderWidth={1}
                                  onFocus={() => {
                                    setEmailError(false);
                                    setEmailErrorText("");
                                  }}
                                />
                              </InputGroup>
                              {emailError && (
                                <div style={{ color: "red" }}>
                                  {emailErrorText}
                                </div>
                              )}
                            </FormControl>
                          );
                        }}
                      </Field>
                      {signupButtonText === "Verify OTP" ? (
                        <>
                          <Field name={"name"}>
                            {({ field, form }: { field: any; form: any }) => {
                              return (
                                <FormControl
                                  isRequired
                                  display={"flex"}
                                  flexDir={"column"}
                                >
                                  <FormLabel as={"b"} fontSize={"20px"}>
                                    Full Name (as per Pancard)
                                  </FormLabel>
                                  <InputGroup>
                                    <InputLeftElement pointerEvents="none">
                                      <FaUser color="gray.600" />
                                    </InputLeftElement>
                                    <Input
                                      {...field}
                                      isDisabled={
                                        signupButtonText === "Verify OTP"
                                          ? false
                                          : true
                                      }
                                      bg={"white"}
                                      placeholder={"Full Name"}
                                      type={"text"}
                                      isRequired
                                    />
                                  </InputGroup>
                                </FormControl>
                              );
                            }}
                          </Field>
                          <Field name={"mobile"}>
                            {({ field, form }: { field: any; form: any }) => {
                              return (
                                <FormControl
                                  display={"flex"}
                                  flexDir={"column"}
                                  isRequired
                                >
                                  <FormLabel as={"b"} fontSize={"20px"}>
                                    Mobile Number
                                  </FormLabel>
                                  <InputGroup>
                                    <InputLeftElement pointerEvents="none">
                                      <PhoneIcon color="gray.600" />
                                    </InputLeftElement>
                                    <Input
                                      {...field}
                                      bg={"white"}
                                      isDisabled={
                                        signupButtonText === "Verify OTP"
                                          ? false
                                          : true
                                      }
                                      placeholder={"Mobile Number"}
                                      type={"tel"}
                                      minLength={10}
                                      maxLength={10}
                                      pattern="[0-9]{10}"
                                    />
                                  </InputGroup>
                                </FormControl>
                              );
                            }}
                          </Field>
                          <Field name="password">
                            {({ field, form }: { field: any; form: any }) => {
                              const { value, onBlur } = field;
                              const { touched, errors } = form;

                              return (
                                <FormControl
                                  display="flex"
                                  flexDir="column"
                                  isRequired
                                >
                                  <FormLabel as="b" fontSize="20px">
                                    Password
                                  </FormLabel>
                                  <InputGroup>
                                    <InputLeftElement>
                                      <LockIcon />
                                    </InputLeftElement>
                                    <Input
                                      {...field}
                                      bg="white"
                                      isDisabled={
                                        signupButtonText === "Verify OTP"
                                          ? false
                                          : true
                                      }
                                      placeholder="Password"
                                      type="password"
                                      onBlur={onBlur}
                                    />
                                  </InputGroup>
                                  {touched.password && errors.password && (
                                    <div>{errors.password}</div>
                                  )}
                                </FormControl>
                              );
                            }}
                          </Field>

                          <Field name="confirmPassword">
                            {({ field, form }: { field: any; form: any }) => {
                              const { value } = field;
                              // const passwordFieldValue = form.values.password; // Assuming the password field has the name "password"

                              const handleConfirmPasswordChange = (
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                field.onChange(e);
                                if (e.target.value !== form.values.password) {
                                  setPasswordError(
                                    "Confirm Passwords do not match with Password"
                                  );
                                  setSignupDisabled(true);
                                } else {
                                  setPasswordError("");
                                  setSignupDisabled(false);
                                }
                              };

                              return (
                                <FormControl
                                  display="flex"
                                  flexDir="column"
                                  isRequired
                                >
                                  <FormLabel as="b" fontSize="20px">
                                    Confirm Password
                                  </FormLabel>
                                  <InputGroup>
                                    <InputLeftElement>
                                      <LockIcon />
                                    </InputLeftElement>
                                    <Input
                                      {...field}
                                      bg="white"
                                      placeholder="Re-Enter Password"
                                      isDisabled={
                                        signupButtonText === "Verify OTP"
                                          ? false
                                          : true
                                      }
                                      type="password"
                                      onChange={handleConfirmPasswordChange}
                                    />
                                  </InputGroup>
                                  {passwordError && (
                                    <div style={{ color: "red" }}>
                                      {passwordError}
                                    </div>
                                  )}
                                </FormControl>
                              );
                            }}
                          </Field>
                          <Field name={"referby"}>
                            {({ field, form }: { field: any; form: any }) => {
                              return (
                                <FormControl
                                  display={"flex"}
                                  flexDir={"column"}
                                  isRequired
                                >
                                  <FormLabel as={"b"} fontSize={"20px"}>
                                    Refer Id
                                  </FormLabel>
                                  <InputGroup>
                                    <InputLeftElement>
                                      <RiUserShared2Fill />
                                    </InputLeftElement>
                                    <Input
                                      {...field}
                                      // value={ref ? ref : ""}
                                      bg={"white"}
                                      placeholder={"Refer Id"}
                                      isDisabled={
                                        !!ref ||
                                        signupButtonText !== "Verify OTP"
                                      }
                                      type={"text"}
                                      color={
                                        refercodeError ? "red" : "gray.600"
                                      }
                                      borderColor={
                                        refercodeError ? "red" : "gray.300"
                                      }
                                      borderWidth={1}
                                      onFocus={() => {
                                        setRefercodeError(false);
                                        setRefercodeErrorText("");
                                      }}
                                    />
                                  </InputGroup>
                                  {refercodeError && (
                                    <div style={{ color: "red" }}>
                                      {refercodeErrorText}
                                    </div>
                                  )}
                                </FormControl>
                              );
                            }}
                          </Field>

                          <Field name="otp">
                            {({ field, form }: { field: any; form: any }) => {
                              const handleOTPChange = (
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const { value } = e.target;
                                const sanitizedValue = value.replace(/\D/g, ""); // Remove non-numeric characters
                                form.setFieldValue("otp", sanitizedValue);
                              };

                              return (
                                <FormControl
                                  display="flex"
                                  flexDir="column"
                                  isRequired
                                >
                                  <FormLabel as="b" fontSize="20px">
                                    OTP
                                  </FormLabel>
                                  <InputGroup>
                                    <InputLeftElement pointerEvents="none">
                                      <PiPasswordBold />
                                    </InputLeftElement>
                                    <Input
                                      {...field}
                                      isDisabled={
                                        signupButtonText === "Verify OTP"
                                          ? false
                                          : true
                                      }
                                      name="otp"
                                      bg="white"
                                      placeholder="XXXXXX"
                                      type="password"
                                      value={field.value}
                                      onChange={handleOTPChange}
                                      color={
                                        verifyOtpError ? "red" : "gray.600"
                                      }
                                      borderColor={
                                        verifyOtpError ? "red" : "gray.300"
                                      }
                                      borderWidth={1}
                                      onFocus={() => {
                                        setVerifyOtpError(false);
                                        setVerifyOtpErrorText("");
                                      }}
                                      maxLength={6}
                                    />
                                  </InputGroup>
                                  {verifyOtpError && (
                                    <div style={{ color: "red" }}>
                                      {verifyOtpErrorText}
                                    </div>
                                  )}
                                </FormControl>
                              );
                            }}
                          </Field>
                        </>
                      ) : (
                        <></>
                      )}
                    </Flex>

                    <Flex justifyContent={"center"} mt={4}>
                      <Button isDisabled={signupDisabled} type="submit">
                        {signupButtonText}
                      </Button>
                    </Flex>
                  </Form>
                </Formik>
              </Stack>
            </VStack>
          )}
        </VStack>
        {/* For sending OTP of Forgot Password */}
        <>
          <Modal
            closeOnOverlayClick={false}
            isOpen={forgotOpen}
            onClose={forgotOnClose}
            isCentered
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader textAlign={"center"}>Forgot Password??</ModalHeader>
              <Formik
                initialValues={initialValuesForgotPassword}
                onSubmit={(values, { setSubmitting }) => {
                  forgotPassword(values);
                }}
              >
                <Form>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <Field name={"email"}>
                      {({ field, form }: { field: any; form: any }) => {
                        return (
                          <FormControl
                            display={"flex"}
                            flexDir={"column"}
                            isRequired
                          >
                            <FormLabel as={"b"} fontSize={"20px"}>
                              Email
                            </FormLabel>
                            <InputGroup>
                              <InputLeftElement pointerEvents="none">
                                <EmailIcon />
                              </InputLeftElement>
                              <Input
                                {...field}
                                name={"email"}
                                bg={"white"}
                                placeholder={"abc@xyz.com"}
                                type={"email"}
                                color={forgotEmailError ? "red" : "gray.600"}
                                borderColor={
                                  forgotEmailError ? "red" : "gray.300"
                                }
                                borderWidth={1}
                                onFocus={() => {
                                  setForgotEmailError(false);
                                  setForgotEmailErrorText("");
                                }}
                              />
                            </InputGroup>
                            {forgotEmailError && (
                              <div style={{ color: "red" }}>
                                {forgotEmailErrorText}
                              </div>
                            )}
                          </FormControl>
                        );
                      }}
                    </Field>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      isDisabled={sendOtpButton}
                      colorScheme="green"
                      mr={3}
                      type={"submit"}
                    >
                      {sendOtpButtonText}
                    </Button>
                  </ModalFooter>
                </Form>
              </Formik>
            </ModalContent>
          </Modal>
        </>
        {/* For verifying SignUp OTP */}
        <>
          <Modal
            closeOnOverlayClick={false}
            isOpen={otpOpen}
            onClose={otpOnClose}
            isCentered
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader textAlign={"center"}>Enter OTP</ModalHeader>
              <Formik
                initialValues={initialValuesSignUpOtp}
                onSubmit={(values, { setSubmitting }) => {
                  verifySignUpOtp(values);
                }}
              >
                <Form>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <Field name="otp">
                      {({ field, form }: { field: any; form: any }) => {
                        const handleOTPChange = (
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const { value } = e.target;
                          const sanitizedValue = value.replace(/\D/g, ""); // Remove non-numeric characters
                          form.setFieldValue("otp", sanitizedValue);
                        };

                        return (
                          <FormControl
                            display="flex"
                            flexDir="column"
                            isRequired
                          >
                            <FormLabel as="b" fontSize="20px">
                              OTP
                            </FormLabel>
                            <InputGroup>
                              <InputLeftElement pointerEvents="none">
                                <PiPasswordBold />
                              </InputLeftElement>
                              <Input
                                {...field}
                                name="otp"
                                bg="white"
                                placeholder="XXXXXX"
                                type="password"
                                value={field.value}
                                onChange={handleOTPChange}
                                color={verifyOtpError ? "red" : "gray.600"}
                                borderColor={
                                  verifyOtpError ? "red" : "gray.300"
                                }
                                borderWidth={1}
                                onFocus={() => {
                                  setVerifyOtpError(false);
                                  setVerifyOtpErrorText("");
                                }}
                                maxLength={6}
                              />
                            </InputGroup>
                            {verifyOtpError && (
                              <div style={{ color: "red" }}>
                                {verifyOtpErrorText}
                              </div>
                            )}
                          </FormControl>
                        );
                      }}
                    </Field>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      isDisabled={sendOtpButton}
                      colorScheme="green"
                      mr={3}
                      type={"submit"}
                    >
                      {sendOtpButtonText}
                    </Button>
                  </ModalFooter>
                </Form>
              </Formik>
            </ModalContent>
          </Modal>
        </>
        {/* For Adding Pancard Number after Login or Signup */}
        <>
          <Modal
            closeOnOverlayClick={false}
            isOpen={pancardOpen}
            onClose={pancardOnClose}
            isCentered
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader textAlign={"center"}>
                Enter Pancard Number
              </ModalHeader>
              <Formik
                initialValues={initialValuesSignUpOtp}
                onSubmit={(values, { setSubmitting }) => {
                  verifySignUpOtp(values);
                }}
              >
                <Form>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <Field name={"pancard"}>
                      {({ field, form }: { field: any; form: any }) => {
                        return (
                          <FormControl
                            display={"flex"}
                            flexDir={"column"}
                            isRequired
                          >
                            <FormLabel as={"b"} fontSize={"20px"}>
                              Pancard Number
                            </FormLabel>
                            <InputGroup>
                              <InputLeftElement>
                                <BiIdCard />
                              </InputLeftElement>
                              <Input
                                {...field}
                                bg={"white"}
                                placeholder={"Enter 10 digit Pancard Number"}
                                type={"text"}
                                minLength={10}
                                maxLength={10}
                                textTransform={"uppercase"}
                                _placeholder={{ textTransform: "none" }}
                                color={pancardError ? "red" : "gray.600"}
                                borderColor={pancardError ? "red" : "gray.300"}
                                borderWidth={1}
                                onFocus={() => {
                                  setpancardError(false);
                                  setPancardErrorText("");
                                }}
                              />
                            </InputGroup>
                            {pancardError && (
                              <div style={{ color: "red" }}>
                                {pancardErrorText}
                              </div>
                            )}
                          </FormControl>
                        );
                      }}
                    </Field>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      isDisabled={sendOtpButton}
                      colorScheme="green"
                      mr={3}
                      type={"submit"}
                    >
                      {sendOtpButtonText}
                    </Button>
                  </ModalFooter>
                </Form>
              </Formik>
            </ModalContent>
          </Modal>
        </>
        {/* For Verifying OTP and setting New Password */}
        <>
          <Modal
            closeOnOverlayClick={false}
            isOpen={verifyOtpOpen}
            onClose={verifyOtpOnClose}
            isCentered
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader textAlign={"center"}>Verify OTP</ModalHeader>
              <Formik
                initialValues={initialOtpForgotPassword}
                onSubmit={(values, { setSubmitting }) => {
                  verifyOTP(values);
                }}
              >
                <Form>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <Field name={"email"}>
                      {({ field, form }: { field: any; form: any }) => {
                        return (
                          <FormControl
                            display={"flex"}
                            flexDir={"column"}
                            isRequired
                            isDisabled
                          >
                            <FormLabel as={"b"} fontSize={"20px"}>
                              Email
                            </FormLabel>
                            <InputGroup>
                              <InputLeftElement pointerEvents="none">
                                <EmailIcon />
                              </InputLeftElement>
                              <Input
                                {...field}
                                name={"email"}
                                bg={"white"}
                                placeholder={"abc@xyz.com"}
                                type={"email"}
                                color={emailError ? "red" : "gray.600"}
                                borderColor={emailError ? "red" : "gray.300"}
                                borderWidth={1}
                                onFocus={() => {
                                  setEmailError(false);
                                  setEmailErrorText("");
                                }}
                              />
                            </InputGroup>
                            {emailError && (
                              <div style={{ color: "red" }}>
                                {emailErrorText}
                              </div>
                            )}
                          </FormControl>
                        );
                      }}
                    </Field>
                    <Field name="otp">
                      {({ field, form }: { field: any; form: any }) => {
                        const handleOTPChange = (
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const { value } = e.target;
                          const sanitizedValue = value.replace(/\D/g, ""); // Remove non-numeric characters
                          form.setFieldValue("otp", sanitizedValue);
                        };

                        return (
                          <FormControl
                            display="flex"
                            flexDir="column"
                            isRequired
                          >
                            <FormLabel as="b" fontSize="20px">
                              OTP
                            </FormLabel>
                            <InputGroup>
                              <InputLeftElement pointerEvents="none">
                                <PiPasswordBold />
                              </InputLeftElement>
                              <Input
                                {...field}
                                name="otp"
                                bg="white"
                                placeholder="XXXXXX"
                                type="password"
                                value={field.value}
                                onChange={handleOTPChange}
                                color={verifyOtpError ? "red" : "gray.600"}
                                borderColor={
                                  verifyOtpError ? "red" : "gray.300"
                                }
                                borderWidth={1}
                                onFocus={() => {
                                  setVerifyOtpError(false);
                                  setVerifyOtpErrorText("");
                                }}
                                maxLength={6}
                              />
                            </InputGroup>
                            {verifyOtpError && (
                              <div style={{ color: "red" }}>
                                {verifyOtpErrorText}
                              </div>
                            )}
                          </FormControl>
                        );
                      }}
                    </Field>
                    <Field name={"newPassword"}>
                      {({ field, form }: { field: any; form: any }) => {
                        return (
                          <FormControl
                            display={"flex"}
                            flexDir={"column"}
                            isRequired
                          >
                            <FormLabel as={"b"} fontSize={"20px"}>
                              New Password
                            </FormLabel>
                            <InputGroup>
                              <InputLeftElement pointerEvents="none">
                                <LockIcon />
                              </InputLeftElement>
                              <Input
                                {...field}
                                name={"newPassword"}
                                bg={"white"}
                                placeholder={"New Password"}
                                type={"password"}
                                // color={emailError ? "red" : "gray.600"}
                                // borderColor={emailError ? "red" : "gray.300"}
                                borderWidth={1}
                                // onFocus={() => {
                                //   setEmailError(false);
                                //   setEmailErrorText("");
                                // }}
                              />
                            </InputGroup>
                          </FormControl>
                        );
                      }}
                    </Field>
                    <Field name="confirmNewPassword">
                      {({ field, form }: { field: any; form: any }) => {
                        const { value } = field;
                        // const passwordFieldValue = form.values.password; // Assuming the password field has the name "password"

                        const handleConfirmPasswordChange = (
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          field.onChange(e);
                          if (e.target.value !== form.values.newPassword) {
                            setConfirmNewPasswordText(
                              "Confirm Passwords do not match with Password"
                            );
                            setConfirmPasswordError(true);
                            setNewPasswordButton(true);
                          } else {
                            setConfirmNewPasswordText("");
                            setConfirmPasswordError(false);
                            setNewPasswordButton(false);
                          }
                        };

                        return (
                          <FormControl
                            display="flex"
                            flexDir="column"
                            isRequired
                          >
                            <FormLabel as="b" fontSize="20px">
                              Confirm Password
                            </FormLabel>
                            <InputGroup>
                              <InputLeftElement>
                                <LockIcon />
                              </InputLeftElement>
                              <Input
                                {...field}
                                bg="white"
                                placeholder="Re-Enter Password"
                                type="password"
                                onChange={handleConfirmPasswordChange}
                              />
                            </InputGroup>
                            {confirmPasswordError && (
                              <div style={{ color: "red" }}>
                                {confirmNewPasswordText}
                              </div>
                            )}
                          </FormControl>
                        );
                      }}
                    </Field>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      isDisabled={newPasswordButton}
                      colorScheme="green"
                      mr={3}
                      type={"submit"}
                    >
                      {newPasswordText}
                    </Button>
                  </ModalFooter>
                </Form>
              </Formik>
            </ModalContent>
          </Modal>
        </>
      </HStack>
    </Box>
  );
};

export default AuthTemplate;

// import { Box, HStack, Button } from "@chakra-ui/react";
// import { useState } from "react";
// import SignUpForm from "./SignUpForm";
// import ForgotPasswordModal from "./ForgotPasswordModal";
// // import OtpVerificationModal from "./OtpVerificationModal";
// // import PancardModal from "./PancardModal";
// import LoginForm from "./LoginForm";

// const AuthTemplate = () => {
//   const [loginOpen, setLoginOpen] = useState(false);
//   const [signupOpen, setSignupOpen] = useState(false);
//   const [forgotOpen, setForgotOpen] = useState(false);
//   const [otpOpen, setOtpOpen] = useState(false);
//   const [pancardOpen, setPancardOpen] = useState(false);

//   return (
//     <Box>
//       <HStack spacing={4} justify="center">
//         <Button colorScheme="blue" onClick={() => setLoginOpen(true)}>
//           Login
//         </Button>
//         <Button colorScheme="green" onClick={() => setSignupOpen(true)}>
//           Sign Up
//         </Button>
//       </HStack>

//       <LoginForm isOpen={loginOpen} onClose={() => setLoginOpen(false)} />
//       <SignUpForm isOpen={signupOpen} onClose={() => setSignupOpen(false)} />
//       <ForgotPasswordModal
//         isOpen={forgotOpen}
//         onClose={() => setForgotOpen(false)}
//       />
//       {/* <OtpVerificationModal isOpen={otpOpen} onClose={() => setOtpOpen(false)} />
//       <PancardModal isOpen={pancardOpen} onClose={() => setPancardOpen(false)} /> */}
//     </Box>
//   );
// };

// export default AuthTemplate;
