import { Box, Divider, Flex, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import { motion, Transition } from "framer-motion";
import { SocialIcon } from "react-social-icons";
import { useNavigate } from "react-router-dom";

type Props = {};

const TopLinks = () => {
  const socialIconSize = useBreakpointValue({ base: "20px", md: "30px" });

  return (
    <>
      <Flex
        h={{ base: 10, md: 12 }}
        px={{ base: 4, md: 12 }}
        justify="space-between"
        bg="gray.600"
        color="whiteAlpha.700"
        _hover={{ color: "whiteAlpha.900" }}
      >
        {/* Company Name */}
        <Box
          as={motion.div}
          initial={{ y: -500, x: -200, opacity: 0, scale: 0.2 }}
          animate={{
            y: 0,
            x: 0,
            opacity: 1,
            scale: 1,
            transition: { duration: 0.6 },
          }}
          fontSize={{ base: "sm", md: "md" }}
          cursor="pointer"
          alignSelf={"center"}
        >
          ShoppingBoom Retails Pvt Ltd
        </Box>

        {/* Social Icons */}
        <Flex
          gap={4}
          align="center"
          as={motion.div}
          initial={{ y: -500, x: 200, opacity: 0, scale: 0.2 }}
          animate={{
            y: 0,
            x: 0,
            opacity: 1,
            scale: 1,
            transition: { duration: 0.6 },
          }}
        >
          {["instagram", "facebook", "twitter", "youtube"].map((platform) => (
            <SocialIcon
              key={platform}
              url={`https://${platform}.com/shoppingboom`}
              fgColor="white"
              bgColor="transparent"
              style={{ height: socialIconSize, width: socialIconSize }}
            />
          ))}
        </Flex>
      </Flex>
      <Divider borderColor="blackAlpha.700" />
    </>
  );
};

export default TopLinks;
