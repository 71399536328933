import { Box, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { authInstance } from "../../Axios/Axios";

type Props = {
  currentUser: any;
  token: string;
};
const Earnings = ({ currentUser, token }: Props) => {
  const [todaysEarningsAmount, setTodaysEarningAmount] = useState(0);
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const config = {
    headers: headers,
  };
  const getTransactions = async () => {
    let res = await authInstance.get(
      `/getTodaysTransaction/${currentUser.id}`,
      config
    );
    console.log(res.data.message);
    res.data.message.forEach((el: any) => {
      console.log(+el.payment);
      setTodaysEarningAmount((prevAmount) => prevAmount + +el.payment);
    });
  };
  useEffect(() => {
    getTransactions();
  }, [currentUser]);
  return (
    <Box p={4} border="1px solid #ddd" borderRadius="lg">
      <Text fontSize="lg" fontWeight="bold">
        Today's Earnings
      </Text>
      <Text fontSize="2xl" color="blue.500">
        ₹{todaysEarningsAmount}
      </Text>
    </Box>
  );
};

export default Earnings;
