import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import SingleProductCart from "./SingleProductCart";
import LoginContext from "../../Context/Login/LoginContext";
import LoadingContext from "../../Context/Loading/LoadingContext";

type Props = {
  width: string;
  products: any;
  totalSum: number;
  setTotalSum: any;
  discountedPrice: any;
  setDiscountedPrice: any;
  discount: any;
  setDiscount: any;
  currentUser: any;
};

const ProductsDetails = ({
  width,
  products,
  totalSum,
  setTotalSum,
  discountedPrice,
  setDiscountedPrice,
  discount,
  setDiscount,
  currentUser,
}: Props) => {
  const { cartCount } = useContext(LoginContext);
  const { loading } = useContext(LoadingContext);
  console.log(products);
  useEffect(() => {
    if (products && products.length > 0) {
      const sum = products.reduce(
        (acc: number, el: any) =>
          acc +
          (el.prices[0] && el.prices[0].original ? el.prices[0].original : 0) *
            el.qty,
        0
      );
      const totalDiscount = products.reduce(
        (acc: number, el: any) =>
          acc +
          ((el.prices[0] && el.prices[0].original ? el.prices[0].original : 0) -
            (el.prices[0] && el.prices[0].current ? el.prices[0].current : 0)) *
            el.qty,
        0
      );
      setTotalSum(sum);
      setDiscountedPrice(totalDiscount);
    }
  }, [products, setTotalSum]);

  return (
    <Flex
      flexDir="column"
      gap={2}
      w="100%"
      h="100%"
      maxW={{ base: "100%", md: width }}
      px={{ base: 2, md: 4 }}
    >
      {/* Cart Header */}
      <Flex
        p={3}
        fontSize={{ base: "24px", md: "32px" }}
        position="sticky"
        top={0}
        h="15%"
        bg="white"
        boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
        borderRadius="md"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Text as="b">My Cart</Text>
        <Text fontSize={{ base: "16px", md: "20px" }}>{cartCount} Items</Text>
      </Flex>

      {/* Cart Items */}
      <Flex
        flexDir="column"
        gap={2}
        overflowY="auto"
        h="85%"
        maxH={{ base: "400px", md: "85%" }}
        px={{ base: 1, md: 3 }}
      >
        {loading
          ? Array.from({ length: 2 }).map((_, index) => (
              <SingleProductCart
                setTotalSum={setTotalSum}
                setDiscountedPrice={setDiscountedPrice}
                currentUser={0}
                key={index}
                product={{}}
              />
            ))
          : products?.map((el: any, index: number) => (
              <SingleProductCart
                key={index}
                setTotalSum={setTotalSum}
                setDiscountedPrice={setDiscountedPrice}
                currentUser={currentUser}
                product={el}
              />
            ))}
      </Flex>
    </Flex>
  );
};

export default ProductsDetails;
