import React, { useState } from "react";
import {
  Box,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Button,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from "@chakra-ui/react";

interface Props {
  applyFilters: any;
}

const SideFilter = ({ applyFilters }: Props) => {
  const [finalPriceRange, setFinalPriceRange] = useState(99999);
  const [selectedSortOption, setSelectedSortOption] = useState("featured");

  const handlePriceRangeChange = (value: number) => {
    setFinalPriceRange(value);
  };

  const applyFiltersAndCloseSidebar = () => {
    const filters = {
      priceRange: finalPriceRange,
      sortOption: selectedSortOption,
    };
    applyFilters(filters);
  };

  return (
    <Box
      w="100%"
      p={4}
      bg="white"
      boxShadow="md"
      position="sticky"
      top={16}
      borderRadius="md"
    >
      <Heading size="md">Filters</Heading>

      <Stack spacing={4} mt={4}>
        <Text fontSize="lg">Price Range</Text>
        <Slider min={0} max={100000} step={5000} defaultValue={finalPriceRange} onChange={handlePriceRangeChange}>
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>
        <Text>
          ₹ 0 - {finalPriceRange >= 99999 ? "₹ 100000+" : `₹ ${finalPriceRange}`}
        </Text>
      </Stack>

      <Stack spacing={4} mt={4}>
        <Text fontSize="lg">Sort By</Text>
        <RadioGroup value={selectedSortOption} onChange={setSelectedSortOption}>
          <Radio value="featured">Featured</Radio>
          <Radio value="price-low-to-high">Price: Low to High</Radio>
          <Radio value="price-high-to-low">Price: High to Low</Radio>
        </RadioGroup>
      </Stack>

      <Button mt={4} colorScheme="blue" onClick={applyFiltersAndCloseSidebar}>
        Apply Filters
      </Button>
    </Box>
  );
};

export default SideFilter;
