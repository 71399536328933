import { Box, ListItem, Skeleton, Text, UnorderedList } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import LoadingContext from "../../Context/Loading/LoadingContext";

type Props = {
  selectedProduct: any;
};

const Features = ({ selectedProduct }: Props) => {
  const [features, setFeatures] = useState([]);
  const { loading } = useContext(LoadingContext);
  useEffect(() => {
    if (!loading) {
      setFeatures(selectedProduct.features);
    }
  }, [loading]);
  return (
    <Box>
      <Text as={"b"} fontSize={"20px"}>
        Features :
      </Text>
      <UnorderedList>
        {loading
          ? Array.from({ length: 3 }).map((_, index) => (
              <ListItem>
                <Skeleton w={"100%"} h={4} />
              </ListItem>
            ))
          : features &&
            features.map((el, i) => <ListItem key={i}>{el}</ListItem>)}
        {/* <ListItem>abc</ListItem> */}
      </UnorderedList>
    </Box>
  );
};

export default Features;
