import {
  Box,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import LoginContext from "../../Context/Login/LoginContext";
import { authInstance } from "../../Axios/Axios";
import AuthToast from "../Toast";

type Props = {};

const AddressSelector = ({
  userAddress,
  selectedAddress,
  setSelectedAddress,
}: {
  userAddress: any[];
  selectedAddress: any;
  setSelectedAddress: (value: Number) => void;
}) => {
  const { currentUser, token } = useContext(LoginContext);
  const toast = useToast();
  const headers = { Authorization: `Bearer ${token}` };
  const config = { headers: headers };
  const deleteAddress = async (el: any) => {
    let res = await authInstance.delete(
      `/deleteAddress/${currentUser.id}/${el.id}`,
      config
    );
    if (res.status === 200) {
      AuthToast("", toast, "Address Deleted", "success");
    } else {
      AuthToast("Address Not Deleted", toast, "Something went wrong", "error");
    }
  };
  return (
    <RadioGroup
      onChange={(value: string) => setSelectedAddress(Number(value))}
      value={selectedAddress}
      w={"full"}
      h={"full"}
    >
      <Stack spacing={4} w={"full"}>
        {userAddress.map((el: any) => (
          <Flex
            key={el.id}
            bg={"gray.200"}
            justifyContent={"space-between"}
            borderRadius={"lg"}
            p={4}
            flexDirection={{ base: "column", md: "row" }} // Stack vertically on small screens
          >
            <Radio value={el.id.toString()} border={"1px solid black"} flex={1}>
              <Flex
                justifyContent={"space-between"}
                alignItems={"center"}
                w={"full"}
              >
                <Text
                  fontSize={{ base: "14px", md: "16px" }}
                  fontWeight={"bold"}
                >
                  {el.AddressLine1}
                </Text>
              </Flex>
            </Radio>
            <Box fontSize={"2xl"} cursor="pointer" mt={{ base: 2, md: 0 }}>
              <MdOutlineDeleteOutline onClick={() => deleteAddress(el)} />
            </Box>
          </Flex>
        ))}
      </Stack>
    </RadioGroup>
  );
};

export default AddressSelector;
