import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Flex, useBreakpointValue } from "@chakra-ui/react";
import TopLinks from "../Components/Navbar/TopLinks";
import Navbar from "../Components/Navbar/Navbar";
import CategoryClick from "../Components/Navbar/CategoryClick";
import ImageView from "../Components/SingleProductPage/ImageView";
import ProductInfo from "../Components/SingleProductPage/ProductInfo";
import { authInstance } from "../Axios/Axios";
import { getParamsValue } from "../Components/CommonFunction";
import LoadingContext from "../Context/Loading/LoadingContext";

type Props = {
  scrollTop: boolean;
};

const SingleProductPage = ({ scrollTop }: Props) => {
  const { id } = useParams();
  const [product, setProduct] = useState<any>({});
  const { loading, setLoading } = useContext(LoadingContext);

  const getSingleProduct = async () => {
    setLoading(true);
    let res = await authInstance.get(`/products/${getParamsValue("id", id)}`);
    setProduct(res.data.data[0]);
    setLoading(false);
  };

  useEffect(() => {
    getSingleProduct();
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    if (loading) {
      document.title = `ShoppingBoom.in`;
    } else if (product && product.name) {
      document.title = `${product.brand} ${product.name} : ShoppingBoom.in`;
    }
  }, [product, loading]);

  return (
    <Box minH="100vh">
      <TopLinks />
      <Navbar scrollTop={scrollTop} />
      <CategoryClick />
      
      <Flex 
        direction={{ base: "column", md: "row" }} 
        p={{ base: 4, md: 8 }} 
        gap={6}
      >
        <Box w={{ base: "100%", md: "45%" }} bg="white">
          <ImageView selectedProduct={product} />
        </Box>
        <Box w={{ base: "100%", md: "55%" }} bg="white" p={{ base: 4, md: 8 }}>
          <ProductInfo selectedProduct={product} />
        </Box>
      </Flex>
    </Box>
  );
};

export default SingleProductPage;
