import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";
import React, { useContext } from "react";
import LoginContext from "../../Context/Login/LoginContext";
import { authInstance } from "../../Axios/Axios";
import AuthToast from "../Toast";

type Props = {};

const SelectedAddressDetails = ({
  userAddress,
  selectedAddress,
}: {
  userAddress: any[];
  selectedAddress: string | null;
}) => {
  const addressDetails = userAddress.find(
    (el: any) => el.AddressLine1 === selectedAddress
  );
  const { currentUser, token } = useContext(LoginContext);
  const headers = { Authorization: `Bearer ${token}` };
  const config = { headers: headers };
  const toast = useToast();
  const makeDefaultAddress = async () => {
    let body = {
      IsDefault: true,
    };
    let res = await authInstance.patch(
      `/updateAddress/${currentUser.id}/${addressDetails.id}`,
      body,
      config
    );
    console.log(res.status);
    if (res.status === 200) {
      AuthToast("", toast, "Address Updated", "success");
    }
  };

  //   console.log(addressDetails);
  if (!addressDetails) return <Text>No Address Selected</Text>;

  return (
    <Flex direction={"column"} gap={2}>
      <Text fontSize={{ base: "16px", md: "18px" }} fontWeight={"bold"} mb={2}>
        Selected Address Details:
      </Text>
      <Text>Address Line 1: {addressDetails.AddressLine1}</Text>
      <Text>Address Line 2: {addressDetails.AddressLine2}</Text>
      <Text>City: {addressDetails.City}</Text>
      <Text>State: {addressDetails.State}</Text>
      <Text>Postal Code: {addressDetails.PostalCode}</Text>
      <Text>Country: {addressDetails.Country}</Text>

      {!addressDetails.IsDefault ? (
        <Flex w="100%" justify="flex-end" align="center">
          <Button onClick={() => makeDefaultAddress()} colorScheme="green">
            Make Default
          </Button>
        </Flex>
      ) : null}
    </Flex>
  );
};

export default SelectedAddressDetails;
