import React from "react";
import { Box, Flex, HStack, Text, Stack } from "@chakra-ui/react";

const Footer = () => {
  return (
    <Box bg="gray.500" color="white">
      <Flex
        maxW="1200px"
        mx="auto"
        py="4"
        alignItems="center"
        justifyContent="space-between"
        flexDirection={{ base: "column", md: "row" }} // Stack items on small screens
      >
        <HStack
          spacing="4"
          display={{ base: "none", sm: "flex" }} // Hide on small screens
          mb={{ base: 4, sm: 0 }} // Add margin-bottom on small screens
        >
          <Text fontSize="lg" fontWeight="bold">
            ShoppingBoom
          </Text>
          <Text fontSize="sm">About Us</Text>
          <Text fontSize="sm">Contact Us</Text>
          <Text fontSize="sm">Terms of Service</Text>
          <Text fontSize="sm">Privacy Policy</Text>
        </HStack>

        {/* For smaller screens, stack the footer items vertically */}
        <Stack
          spacing="2"
          align="center"
          display={{ base: "flex", sm: "none" }} // Show only on small screens
        >
          <Text fontSize="sm">About Us</Text>
          <Text fontSize="sm">Contact Us</Text>
          <Text fontSize="sm">Terms of Service</Text>
          <Text fontSize="sm">Privacy Policy</Text>
        </Stack>

        <Text fontSize="sm" textAlign={{ base: "center", sm: "right" }}>
          &copy; {new Date().getFullYear()} ShoppingBoom
        </Text>
      </Flex>
    </Box>
  );
};

export default Footer;
