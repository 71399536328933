import { Box, Button, Flex, Stack, useMediaQuery } from "@chakra-ui/react";
import React, { useState } from "react";
import Description from "./Description";
import Features from "./Features";
import Specification from "./Specification";

type Props = {
  selectedProduct: any;
};

const SwitchTabs = ({ selectedProduct }: Props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  const handleSwitchClick = (activeNumber: number) => {
    setActiveTab(activeNumber);
  };

  return (
    <Flex flexDir={"column"} gap={3}>
      {/* Tabs */}
      <Stack
        w={"100%"}
        mt={4}
        spacing={2}
        direction="row"
        align="center"
        overflowX={isMobile ? "auto" : "unset"}
        whiteSpace={isMobile ? "nowrap" : "normal"}
        css={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Button
          colorScheme={activeTab === 0 ? "blue" : "gray"}
          variant={activeTab === 0 ? "solid" : "outline"}
          size={isMobile ? "sm" : "md"}
          onClick={() => handleSwitchClick(0)}
        >
          Description
        </Button>
        <Button
          colorScheme={activeTab === 1 ? "blue" : "gray"}
          variant={activeTab === 1 ? "solid" : "outline"}
          size={isMobile ? "sm" : "md"}
          onClick={() => handleSwitchClick(1)}
        >
          Features
        </Button>
        <Button
          colorScheme={activeTab === 2 ? "blue" : "gray"}
          variant={activeTab === 2 ? "solid" : "outline"}
          size={isMobile ? "sm" : "md"}
          onClick={() => handleSwitchClick(2)}
        >
          Specifications
        </Button>
      </Stack>

      {/* Tab Content */}
      <Box>
        {activeTab === 0 ? (
          <Description selectedProduct={selectedProduct} />
        ) : activeTab === 1 ? (
          <Features selectedProduct={selectedProduct} />
        ) : (
          <Specification selectedProduct={selectedProduct} />
        )}
      </Box>
    </Flex>
  );
};

export default SwitchTabs;
