import React, { useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes, useMatch } from "react-router-dom";
import Login from "../Pages/Login";
import Dashboard from "../Pages/Dashboard";
import PrivateRoute from "./PrivateRoute";
import LoginContext from "../Context/Login/LoginContext";
import SingleProductPage from "../Pages/SingleProductPage";
import Cart from "../Pages/Cart";
import SearchPage from "../Pages/SearchPage";
import WithdrawAmount from "../Pages/WithdrawAmount";
import Buynow from "../Pages/Buynow";
import Checkout from "../Pages/Checkout";
import UserProfile from "../Pages/UserProfile";

type Props = {};

const AllRoutes = (props: Props) => {
  const { loginState } = useContext(LoginContext);
  const isLoginPath = useMatch("/login");
  // const [product, setProduct] = useState([]);
  // console.log(loginState);
  // console.log(
  //   <PrivateRoute>
  //     <Dashboard />
  //   </PrivateRoute>
  // );
  // const getData = async () => {
  //   setProduct(await productData1());
  //   console.log(product);
  // };
  // useEffect(() => {
  //   getData();
  // }, []);
  const [scrollTop, setScrollTop] = useState(true);
  useEffect(() => {
    onscroll = () => {
      // console.log(window.scrollY);
      if (window.scrollY >= 50) {
        setScrollTop(false);
        // console.log("hyee")
        // console.log(scrollTop);
      } else {
        setScrollTop(true);
        // console.log(scrollTop);
      }
    };
  }, []);
  // console.log(window.location.href);
  useEffect(() => {
    setScrollTop(true);
    console.log(scrollTop);
  }, [window.location.href]);
  return (
    <Routes>
      <Route
        path="/"
        element={
          // <PrivateRoute>
          <Dashboard scrollTop={scrollTop} />
          // </PrivateRoute>
        }
      ></Route>

      <Route
        path={isLoginPath ? "login" : "/login/source=/:source"}
        element={loginState === true ? <Navigate to={"/"} /> : <Login />}
      ></Route>
      <Route
        path="/product/:id"
        element={<SingleProductPage scrollTop={scrollTop} />}
      ></Route>
      <Route path="/cart/:id" element={<Cart scrollTop={scrollTop} />}></Route>
      <Route
        path="/profile/:id"
        element={<UserProfile scrollTop={scrollTop} />}
      ></Route>
      <Route
        path="/checkout/:id/:pId"
        element={<Checkout scrollTop={scrollTop} />}
      ></Route>
      <Route
        path="/searchProduct/:id"
        element={<SearchPage scrollTop={scrollTop} />}
      ></Route>
      <Route
        path="/withdrawAmount/:id"
        element={<WithdrawAmount scrollTop={scrollTop} />}
      ></Route>
      {/* /withdrawAmount/ */}
      <Route
        path={`/buy/:id`}
        element={<Buynow scrollTop={scrollTop} />}
      ></Route>
    </Routes>
  );
};

export default AllRoutes;
