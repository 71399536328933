import { Box, Text, Button, Input, useToast } from "@chakra-ui/react";
import { copyToClipboard } from "../CommonFunction";

type Props = {
  currentUser: any;
  token: string;
};
const ReferralLink = ({ currentUser, token }: Props) => {
  const referralId = currentUser.referId;
  const referralLink = `${window.location.origin}/login?ref=${referralId}`;
  const toast = useToast();

  return (
    <Box p={4} border="1px solid #ddd" borderRadius="lg">
      <Text fontSize="lg" fontWeight="bold">
        Referral Link
      </Text>
      <Input value={referralLink} isReadOnly />
      <Button
        mt={2}
        colorScheme="teal"
        onClick={() => {
          copyToClipboard(referralLink, toast);
        }}
      >
        Copy Link
      </Button>
    </Box>
  );
};

export default ReferralLink;
