import { createContext } from "react";

export type LoginContextType = {
  loginState: boolean | undefined;
  setLoginState: React.Dispatch<React.SetStateAction<boolean>>;
  currentUser: any;
  cartCount: number;
  token: string;
  setCartCount: React.Dispatch<React.SetStateAction<number>>;
};

const LoginContext = createContext<LoginContextType>({
  loginState: false,
  setLoginState: () => {},
  currentUser: {},
  cartCount: 0,
  token: "",
  setCartCount: () => {},
});

export default LoginContext;
