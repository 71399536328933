import {
  Box,
  Button,
  Flex,
  Image,
  Skeleton,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import {
  HiExclamationCircle,
  HiPlusCircle,
  HiMinusCircle,
} from "react-icons/hi";
import { changeQty, removeProduct } from "../CommonFunction";
import LoadingContext from "../../Context/Loading/LoadingContext";
import LoginContext from "../../Context/Login/LoginContext";
import { authInstance } from "../../Axios/Axios";
import AuthToast from "../Toast";

type Props = {
  product: any;
  currentUser: any;
  setTotalSum: React.Dispatch<React.SetStateAction<number>>;
  setDiscountedPrice: React.Dispatch<React.SetStateAction<number>>;
};
export const tomorrow = () => {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const today = new Date();
  console.log(today);
  const tom = new Date(today);
  tom.setDate(today.getDate() + 1);
  console.log(tom);
  const day = tom.getDay();
  console.log(day);
  return daysOfWeek[day];
};
const ProductImage = ({
  imageUrl,
  loading,
}: {
  imageUrl: string;
  loading: boolean;
}) => (
  <Flex
    // border={"1px solid red"}
    w={"25%"}
    p={2}
    justify="center"
    align="center"
  >
    <Box h={"100%"} w={"100%"} flexDir={"column"}>
      {loading ? (
        <Skeleton border={"1px solid red"} h={"full"} w={"100%"} />
      ) : (
        <Image h={"full"} alignSelf={"center"} src={imageUrl} />
      )}
    </Box>
  </Flex>
);
const ProductDetails = ({
  product,
  currentUser,
  loading,
  token,
  cartCount,
  setCartCount,
  toast,
  setTotalSum,
  setDiscountedPrice,
}: {
  product: any;
  currentUser: any;
  loading: boolean;
  token: string;
  cartCount: number;
  setCartCount: any;
  toast: any;
  setTotalSum: any;
  setDiscountedPrice: any;
}) => {
  const [qty, setQty] = useState(0);
  console.log(product);

  useEffect(() => {
    setQty(product.qty);
  }, [product]);
  return (
    <Flex
      flexDir="column"
      gap={loading ? 5 : 2}
      w={{ base: "100%", md: "75%" }}
      p={3}
    >
      <Flex flexDir="row" justify="space-between" flexWrap="wrap">
        {loading ? (
          <Skeleton height="10px" w="50%" />
        ) : (
          <Text as="b" textOverflow="ellipsis" noOfLines={1} w="50%">
            {product.brand} {product.product_name}
          </Text>
        )}
        {loading ? (
          <Skeleton height="10px" w="30%" />
        ) : (
          <Text fontSize="14px" alignSelf="center">
            On your doors by{" "}
            <span style={{ color: "green" }}>{`Tomorrow, ${tomorrow()}`}</span>
          </Text>
        )}
      </Flex>

      {/* Category & Seller Info */}
      {loading ? (
        <Skeleton height="10px" w="60%" />
      ) : (
        <Text fontSize="14px" as="b" color="grey">
          in {product.category_name}
        </Text>
      )}
      {loading ? (
        <Skeleton height="10px" w="60%" />
      ) : (
        <Text fontSize="14px" color="grey">
          Sold By : {product.sellby}
        </Text>
      )}

      {/* Pricing & Discount */}
      {loading ? (
        <Skeleton height="10px" w="60%" />
      ) : (
        <Text fontSize="14px" color="grey" alignItems="center">
          <span style={{ textDecoration: "line-through" }}>
            &#8377;{" "}
            {new Intl.NumberFormat("en-IN").format(
              product?.prices[0]?.original || 0
            )}
          </span>
          &nbsp; &nbsp;
          <span
            style={{ fontSize: "18px", fontWeight: "bold", color: "green" }}
          >
            &#8377;{" "}
            {new Intl.NumberFormat("en-IN").format(product?.prices[0]?.current)}
          </span>
          &nbsp;
          <span style={{ color: "green" }}>
            {product?.prices[0]?.discountPercentage}% Off
          </span>
        </Text>
      )}

      {/* Quantity & Remove Button */}
      <Flex color="gray.400" mt={2} justify="space-between" flexWrap="wrap">
        <Flex gap={5}>
          <Text alignSelf="center">Quantity:</Text>
          <Flex alignItems="center" h={8} gap={2}>
            <HiMinusCircle
              cursor="pointer"
              onClick={() => {
                if (qty > 1) {
                  setTotalSum(
                    (totalSum: any) => totalSum - product?.prices[0]?.original
                  );
                  setDiscountedPrice(
                    (a: any) =>
                      a -
                      (product?.prices[0]?.original -
                        product?.prices[0]?.current)
                  );
                  changeQty(
                    product.id,
                    currentUser.id,
                    token,
                    "subtract",
                    qty,
                    setQty,
                    toast
                  );
                } else {
                  AuthToast(
                    "Quantity can't be less than 1",
                    toast,
                    "",
                    "error"
                  );
                }
              }}
            />
            <Box
              w="2rem"
              fontWeight="bold"
              bg="gray.400"
              color="white"
              borderRadius="md"
              textAlign="center"
              fontSize="14px"
            >
              {qty}
            </Box>
            <HiPlusCircle
              cursor="pointer"
              onClick={() => {
                setTotalSum(
                  (totalSum: any) => totalSum + product?.prices[0]?.original
                );
                setDiscountedPrice(
                  (a: any) =>
                    a +
                    (product?.prices[0]?.original - product?.prices[0]?.current)
                );
                changeQty(
                  product.id,
                  currentUser.id,
                  token,
                  "addition",
                  qty,
                  setQty,
                  toast
                );
              }}
            />
          </Flex>
        </Flex>

        {/* Remove Button */}
        <Box mt={{ base: 2, md: 0 }}>
          {loading ? (
            <Skeleton />
          ) : (
            <Button
              colorScheme="orange"
              size="sm"
              onClick={() => {
                setTotalSum(
                  (totalSum: any) => totalSum - product?.prices[0]?.original
                );
                setDiscountedPrice((a: any) => a - product?.prices[0]?.current);
                removeProduct(
                  currentUser.id,
                  product.product_id,
                  token,
                  cartCount,
                  setCartCount,
                  toast
                );
              }}
            >
              Remove from Cart
            </Button>
          )}
        </Box>
      </Flex>
    </Flex>
  );
};
const SingleProductCart = ({
  product,
  currentUser,
  setTotalSum,
  setDiscountedPrice,
}: Props) => {
  const { loading } = useContext(LoadingContext);
  const { token, cartCount, setCartCount } = useContext(LoginContext);
  const toast = useToast();

  return (
    <Flex
      bg="white"
      w="full"
      h={{ base: "auto", md: "250px" }}
      gap={5}
      p={3}
      borderRadius="md"
      boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
      flexDir={{ base: "column", md: "row" }}
      align="center"
    >
      {/* Product Image */}
      <ProductImage
        loading={loading}
        imageUrl={loading ? "" : product.image_urls[0]}
      />

      {/* Product Details */}
      <ProductDetails
        loading={loading}
        currentUser={currentUser}
        product={product}
        token={token}
        cartCount={cartCount}
        setCartCount={setCartCount}
        toast={toast}
        setTotalSum={setTotalSum}
        setDiscountedPrice={setDiscountedPrice}
      />
    </Flex>
  );
};

export default SingleProductCart;
