import { Box } from "@chakra-ui/react";
import React from "react";

type Props = {
  selectedProduct:any
};

const Description = ({selectedProduct}: Props) => {
  return <Box></Box>;
};

export default Description;
