import React, { useEffect } from "react";
import AuthTemplate from "../Components/Authentication/AuthTemplate";
import TopLinks from "../Components/Navbar/TopLinks";
import Navbar from "../Components/Navbar/Navbar";
import { Box, Flex } from "@chakra-ui/react";

type Props = {};

const Login = (props: Props) => {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [window.location.href]);
  useEffect(() => {
    document.title = `ShoppingBoom.in : Login`;
  }, []);
  return (
    <Flex flexDir={"column"} w={"100%"} h={"100vh"}>
      {/* <Box>
        <TopLinks />
      </Box> */}
      <AuthTemplate />
    </Flex>
  );
};

export default Login;
