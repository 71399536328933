import React, { useState } from "react";

import { Box, Flex, IconButton, Image } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import Carousel from "../Carousel";
import { DashboardBanner } from "../../Data/DashboardBanner";
// Install the Swiper modules

const Banner: React.FC = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const swiperRef = React.useRef<any>(null);

  return (
    <Carousel
      dataArray={DashboardBanner}
      style={{ width: "100%", height: "300px" }}
    />
  );
};

export default Banner;
