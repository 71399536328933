import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormControl,
  FormLabel,
  Checkbox,
  Stack,
  Text,
  Box,
} from "@chakra-ui/react";

type Address = {
  AddressLine1: string;
  IsDefault: number;
  [key: string]: any;
};

type AddAddressModalProps = {
  isOpen: boolean;
  onClose: () => void;
  handleAddNewAddress: (newAddress: Address) => void;
};

const AddAddressModal = ({
  isOpen,
  onClose,
  handleAddNewAddress,
}: AddAddressModalProps) => {
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [isDefault, setIsDefault] = useState(false);
  const [country, setCountry] = useState("");

  const handleSubmit = () => {
    const newAddress = {
      AddressLine1: addressLine1,
      AddressLine2: addressLine2,
      City: city,
      State: state,
      PostalCode: postalCode,
      Country: country,
      IsDefault: isDefault ? 1 : 0,
    };
    handleAddNewAddress(newAddress);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={{ base: "full", md: "lg" }}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={{ base: "xl", md: "2xl" }} color="blue.600">
          Add New Address
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Stack spacing={2}>
            <FormControl isRequired>
              <FormLabel>Address Line 1</FormLabel>
              <Input
                placeholder="Enter Address Line 1"
                value={addressLine1}
                onChange={(e) => setAddressLine1(e.target.value)}
                isRequired
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Address Line 2</FormLabel>
              <Input
                placeholder="Enter Address Line 2"
                value={addressLine2}
                onChange={(e) => setAddressLine2(e.target.value)}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>City</FormLabel>
              <Input
                placeholder="Enter City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>State</FormLabel>
              <Input
                placeholder="Enter State"
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Country</FormLabel>
              <Input
                placeholder="Enter Country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Zip Code</FormLabel>
              <Input
                placeholder="Enter Zip Code"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
              />
            </FormControl>

            <Box mt={4}>
              <Checkbox
                isChecked={isDefault}
                onChange={(e) => setIsDefault(e.target.checked)}
              >
                Set as Default Address
              </Checkbox>
            </Box>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={handleSubmit}
            isDisabled={!addressLine1 || !city || !state || !postalCode}
          >
            Save Address
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddAddressModal;
