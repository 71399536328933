import { useContext } from "react";
import { authInstance } from "../Axios/Axios";
import AuthToast from "./Toast";
import LoginContext from "../Context/Login/LoginContext";
export const addToCart = async (
  productId: number,
  currentUserId: number,
  token: string,
  cartCount: number,
  setCartCount: React.Dispatch<React.SetStateAction<number>>,
  toast: any
) => {
  console.log(currentUserId);
  console.log(productId);
  const body = {
    user_id: currentUserId,
    product_id: productId,
  };
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const config = {
    headers: headers,
  };
  try {
    let res = await authInstance.post("cart/items", body, config);
    console.log(res);
    if (res.status === 201) {
      setCartCount(cartCount + 1);
      AuthToast(``, toast, "Item Added To Cart.", "success");
    } else {
      AuthToast(
        ``,
        toast,
        "Something went wrong, Try again in few minutes.",
        "error"
      );
    }
    return;
  } catch (error) {
    console.log(error);
    AuthToast(
      ``,
      toast,
      "Something went wrong, Try again in few minutes.",
      "error"
    );
  }
};
export const changeQty = async (
  productId: number,
  userId: number,
  token: string,
  click: string,
  qty: number,
  setQty: React.Dispatch<React.SetStateAction<number>>,
  toast: any
) => {
  let algorithm = click;
  console.log(algorithm);
  const body = {
    payload:
      algorithm === "subtract" && qty > 1 ? (
        -1
      ) : algorithm === "addition" ? (
        +1
      ) : (
        <></>
      ),
  };
  console.log(body);
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const config = {
    headers: headers,
  };

  const res = await authInstance.patch(
    `update/cart/item/qty/${userId}/${productId}`,
    body,
    config
  );
  if (
    res.status === 200 &&
    res.data.message === "cart quantity updated successfully" &&
    algorithm === "addition"
  ) {
    setQty(qty + 1);
  } else if (
    res.status === 200 &&
    res.data.message === "cart quantity updated successfully" &&
    algorithm === "subtract" &&
    qty > 1
  ) {
    setQty(qty - 1);
  } else {
  }
  console.log(res);
};
export const removeProduct = async (
  userId: number,
  productId: number,
  token: string,
  cartCount: number,
  setCartCount: React.Dispatch<React.SetStateAction<number>>,
  toast: any
) => {
  console.log(userId, productId);
  const body = {
    user_id: userId,
    product_id: productId,
  };
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const config = {
    headers: headers,
  };
  let res = await authInstance.delete(
    `/remove/cart/items/${userId}/${productId}`,
    config
  );
  console.log(res);
  if (res.status === 200) {
    setCartCount(cartCount - 1);
    return true;
  }
};
export const handleProductClick = (el: any, navigate: any) => {
  // const navigate = useNavigate();
  navigate(`/product/${el.name}&id=${el.id}`);
};

export const handleCartClick = (
  navigate: any,
  currentUserId: number,
  loginState: boolean | undefined
) => {
  // const navigate = useNavigate();
  navigate(`/cart/id=${loginState ? currentUserId : "??????"}`);
};
export const buyNowClickHandler = (
  navigate: any,
  pId: number,
  currentUserId: number,
  loginState: boolean | undefined
) => {
  navigate(`/checkout/id=${loginState ? currentUserId : "??????"}/pId=${pId}`);
};
export const buySingleProduct = async (
  id: number | string | null,
  pId: number | string | null,
  addId: number | string | null,
  toast: any,
  config: any,
  navigate: any
) => {
  let body: any = {
    productId: pId,
    addressId: addId,
    paymentMode: "COD",
    qty: 1,
  };
  // console.log(body);
  let res = await authInstance.post(`/makeOrder/${id}`, body, config);
  if (res.status === 200) {
    AuthToast("", toast, "Order Placed Successfully", "success");
    navigate("/");
  } else {
    AuthToast("Order Not Placed", toast, "Something Went Wrong", "error");
  }
};
export const getParamsValue = (a: any, id: any) => {
  const params = new URLSearchParams(id);
  return params.get(a);
};
export const copyToClipboard = (content: string, toast: any) => {
  navigator.clipboard.writeText(content);
  AuthToast("", toast, `Copied ${content}`, "success");
};
export const searchData = async (
  searchText: string | null,
  setSearchResult: any
) => {
  const body = {
    query: searchText,
  };

  try {
    let res = await authInstance.post(`/search-product`, body);

    if (res.data.data) {
      console.log(res.data.data);
      setSearchResult(res.data.data);
    } else {
      console.log("No data found in the response.");
    }
  } catch (error) {
    // if (error.response) {
    //   // The request was made and the server responded with a status code
    //   if (error.response.status === 400) {
    //     console.error("Bad Request: The search query is invalid.");
    //     // Handle the 400 error as needed (e.g., show an error message to the user).
    //   } else if (error.response.status === 404) {
    //     console.error("Not Found: The product was not found.");
    //     // Handle the 404 error as needed (e.g., show a "not found" message).
    //   } else {
    //     console.error("Server Error:", error.response.status);
    //     // Handle other error status codes here if necessary.
    //   }
    // } else if (error.request) {
    //   // The request was made but no response was received
    //   console.error("Request made, but no response received:", error.request);
    //   // Handle this case as needed.
    // } else {
    //   // Something else happened while setting up the request
    //   console.error("Error setting up the request:", error.message);
    //   // Handle this case as needed.
    // }
  }
};
