import {
  Box,
  Button,
  Card,
  CardBody,
  Heading,
  Image,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { BsCart2 } from "react-icons/bs";
import LoginContext from "../Context/Login/LoginContext";
import { useNavigate } from "react-router-dom";
import { addToCart, handleProductClick } from "./CommonFunction";

type Props = {
  el: any;
  index: number;
};

const SingleProductCard = ({ el, index }: Props) => {
  const { currentUser, token, cartCount, setCartCount } =
    useContext(LoginContext);
  const toast = useToast();
  const navigate = useNavigate();

  return (
    <Box
      key={index}
      width={{ base: "80%", sm: "45%", md: "30%", lg: "25%" }} // Responsive width
      flex="0 0 auto"
      mr={index !== 4 ? { base: 2, sm: 4, lg: 5 } : 0} // Responsive margin-right
      borderRadius="md"
      mb={5} // Add margin bottom for spacing
    >
      <Card
        h={"350px"}
        bg={"whiteAlpha.900"}
        onClick={() => {
          handleProductClick(el, navigate);
        }}
      >
        <CardBody
          display={"flex"}
          flexDirection="column"
          gap={5}
          cursor={"pointer"}
        >
          <VStack h={"100%"} spacing={3} justifyContent={"space-between"}>
            <Box h={{ base: "120px", sm: "150px" }} maxH="">
              <Image
                h={"100%"}
                m={"auto"}
                src={el.images[0]}
                _hover={{ transform: "scale(1.1)" }}
                transition={"0.3s"}
              />
            </Box>
            <Heading
              color={"blackAlpha.700"}
              as={"h6"}
              fontSize={{ base: "lg", sm: "xl" }} // Responsive font size for the heading
              textAlign={"center"}
              noOfLines={1}
            >
              {el.name}
            </Heading>
            <Text textAlign={"center"} fontSize={{ base: "sm", sm: "md" }}>
              Rs. <span>{el.prices[0]?.current}</span>
            </Text>
            <Box w={"100%"} h={12}>
              <Button
                h={"100%"}
                colorScheme={"messenger"}
                w={"100%"}
                onClick={(e) => {
                  e.stopPropagation();
                  addToCart(
                    el.id,
                    currentUser.id,
                    token,
                    cartCount,
                    setCartCount,
                    toast
                  );
                }}
              >
                <BsCart2 fontSize={"24px"} />
                &nbsp; Add to Cart
              </Button>
            </Box>
          </VStack>
        </CardBody>
      </Card>
    </Box>
  );
};

export default SingleProductCard;
