import React from "react";

type Props = {
  scrollTop: boolean;
};

const Buynow = (scrollTop: Props) => {
  return <div>Buynow</div>;
};

export default Buynow;
