import { Box, Text } from "@chakra-ui/react";

type Props = {
  currentUser: any;
  token: string;
};
const Wallet = ({ currentUser, token }: Props) => {
  const walletBalance = currentUser.wallet;

  return (
    <Box p={4} border="1px solid #ddd" borderRadius="lg">
      <Text fontSize="lg" fontWeight="bold">
        Wallet Balance
      </Text>
      <Text fontSize="2xl" color="green.500">
        ₹{walletBalance}
      </Text>
    </Box>
  );
};

export default Wallet;
