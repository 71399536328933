import { Box, Flex, Text } from "@chakra-ui/react";
import {
  Swiper as OriginalSwiper,
  SwiperProps,
  SwiperSlide,
} from "swiper/react";
import React, { useContext, useEffect, useState } from "react";
import LoginContext from "../../Context/Login/LoginContext";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFade,
} from "swiper/modules";
import SwiperCore from "swiper";
import { authInstance } from "../../Axios/Axios";
// Install Swiper modules

type Props = {};
type CustomSwiperProps = SwiperProps & {
  modules: any[];
  spaceBetween: number;
  slidesPerView: number;
  navigation: any;
  pagination: any;
  // scrollbar: any;
  // onSwiper: any;
  // onSlideChange: any;
  effect: any;
  autoplay: any;
};

const Swiper = (props: CustomSwiperProps) => {
  const { modules, ...rest } = props;

  return <OriginalSwiper {...rest} />;
};
SwiperCore.use([Navigation, Pagination]);
const WithdrawAmount_Main = (props: Props) => {
  const { currentUser, token } = useContext(LoginContext);
  const [paymentAccounts, setPaymentAccounts] = useState([]);
  console.log(currentUser);
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const config = {
    headers: headers,
  };
  const getData = async () => {
    if (currentUser) {
      const res = await authInstance.get(
        `/getAllPaymentDetails/${currentUser.id}`,
        config
      );
      console.log(res);
      setPaymentAccounts(res.data.message);
    }
  };
  useEffect(() => {
    getData();
  }, [currentUser]);
  const accounts = [
    { id: 1, accountNumber: "123456789", bankName: "ABC Bank" },
    { id: 2, accountNumber: "987654321", bankName: "XYZ Bank" },
    { id: 3, accountNumber: "123456789", bankName: "ABC Bank" },
    { id: 4, accountNumber: "987654321", bankName: "XYZ Bank" },
    { id: 5, accountNumber: "123456789", bankName: "ABC Bank" },
    { id: 6, accountNumber: "987654321", bankName: "XYZ Bank" },
    { id: 7, accountNumber: "123456789", bankName: "ABC Bank" },
    { id: 8, accountNumber: "987654321", bankName: "XYZ Bank" },
    { id: 9, accountNumber: "123456789", bankName: "ABC Bank" },
    { id: 10, accountNumber: "98765432100", bankName: "XYZ Bank" },
    // Add more account details as needed
  ];
  const swiperParams = {
    modules: [Navigation, Pagination, EffectFade],
    autoplay: { delay: 1000 },
    spaceBetween: 50,
    slidesPerView: 3,
    effect: "fade",
    navigation: true,
    pagination: { clickable: true },
    // loop: true,
    // initialSlide: 0, // Set the initial slide to the first slide
  };

  return (
    <Flex border={"1px solid red"} h={"100%"} p={4} flexDir={"column"}>
      <Box border={"1px solid red"} p={5}>
        <Text fontSize={"22px"}>
          Hi,{" "}
          <span
            style={{ fontSize: "28px", fontWeight: "bold", color: "green" }}
          >
            {currentUser.name}
          </span>
        </Text>
      </Box>
      <Box mt={2}>
        <Swiper {...swiperParams} style={{ padding: "3px" }}>
          {paymentAccounts &&
            paymentAccounts.map((el: any, index) => (
              <SwiperSlide
                key={index}
                style={{
                  // transform: `scale(${
                  //   index === swiperParams.slidesPerView - 1 ? 1.2 : 0.8
                  // })`,
                  // transition: "transform 0.3s ease",

                  height: "250px",
                }}
              >
                <Box
                  p={4}
                  textAlign="center"
                  h={"100%"}
                  w={"100%"}
                  // border={"1px solid cyan"}
                  padding={3}
                  borderRadius={"md"}
                  boxShadow={"lg"}
                >
                  {el.upi_id ? <Box>
                    
                  </Box> : <Box></Box>}
                </Box>
              </SwiperSlide>
            ))}
        </Swiper>
      </Box>
    </Flex>
  );
};

export default WithdrawAmount_Main;
