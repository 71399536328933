import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Input,
  Skeleton,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import LoadingContext from "../../Context/Loading/LoadingContext";
import SwitchTabs from "./SwitchTabs";

type Props = {
  selectedProduct: any;
};

const ProductInfo = ({ selectedProduct }: Props) => {
  const { loading } = useContext(LoadingContext);
  const [deliveryPIN, setDeliveryPIN] = useState("");
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  const checkDelivery = () => {
    alert(`Checking delivery at ${deliveryPIN}`);
  };

  return (
    <Flex flexDir={"column"} gap={5} p={isMobile ? 2 : 5}>
      {/* Breadcrumb Navigation */}
      <Box fontSize={"sm"}>
        {loading ? (
          <Skeleton w={"100%"} h={"30px"} />
        ) : (
          <Breadcrumb
            spacing="8px"
            separator={<ChevronRightIcon color="gray.500" />}
            bg={"gray.100"}
            borderRadius={"md"}
            p={2}
            fontSize={isMobile ? "12px" : "14px"}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/">Home</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink
                href={`/searchProduct/category=${
                  selectedProduct?.category || ""
                }`}
              >
                {selectedProduct?.category || ""}
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage cursor={"default"}>
              <BreadcrumbLink
                href={`/product/${selectedProduct?.name || ""}&id=${
                  selectedProduct?.id || ""
                }`}
              >
                {selectedProduct?.name || ""}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        )}
      </Box>

      {/* Product Name & Brand */}
      <Flex flexDir={"column"} gap={2}>
        <Box>
          {loading ? (
            <Skeleton height={"30px"} w={"50%"} />
          ) : (
            <Text noOfLines={2} as={"b"} fontSize={isMobile ? "18px" : "22px"}>
              {selectedProduct?.brand || ""} &nbsp;
              {selectedProduct?.name || ""}
            </Text>
          )}
        </Box>

        {/* Ratings & Reviews */}
        <Flex fontSize={isMobile ? "12px" : "14px"}>
          {loading ? (
            <Skeleton w={"40%"} h={"20px"} />
          ) : (
            <Flex>
              <Badge colorScheme="green" variant="solid" alignSelf={"center"}>
                {"4.6"} &#9733;
              </Badge>
              &nbsp;
              <Text
                ml={2}
                cursor={"pointer"}
                onClick={() => {
                  alert("Going to reviews");
                }}
              >
                {"313"} Ratings&nbsp;
              </Text>
              &&nbsp;<Text>{"13"} Reviews</Text>
            </Flex>
          )}
        </Flex>

        {/* Price Section */}
        <Box>
          {loading ? (
            <Skeleton w={"50%"} h={"30px"} />
          ) : (
            <Text>
              <span
                style={{
                  fontSize: isMobile ? "22px" : "26px",
                  color: "green",
                }}
              >
                &#8377;
                {new Intl.NumberFormat("en-IN").format(
                  selectedProduct?.price?.[0]?.current
                ) || "0"}
              </span>
              &nbsp;
              {selectedProduct?.price?.[0]?.discountPercentage === "0" ? (
                ""
              ) : (
                <span style={{ textDecoration: "line-through", color: "gray" }}>
                  &#8377;
                  {new Intl.NumberFormat("en-IN").format(
                    selectedProduct?.price?.[0]?.original
                  )}
                </span>
              )}
            </Text>
          )}
        </Box>

        {/* Offers Section */}
        <Box>
          <Text color="gray" fontWeight={"bold"} fontSize={"18px"}>
            Available Offers for You:
          </Text>
          <Text fontSize={"14px"} color={"gray"}>
            No Available Offers Right Now
          </Text>
        </Box>
      </Flex>

      {/* Delivery Check */}
      <Flex
        fontSize={isMobile ? "14px" : "16px"}
        color={"gray"}
        gap={3}
        alignItems={"center"}
        flexWrap="wrap"
      >
        <Text>Delivery :</Text>
        <Input
          w={isMobile ? "30%" : "15%"}
          placeholder="XXXXXX"
          value={deliveryPIN}
          onChange={(e) => {
            setDeliveryPIN(e.target.value);
          }}
        />
        <Button colorScheme={"green"} onClick={checkDelivery}>
          Check
        </Button>
      </Flex>

      {/* Tabs */}
      <section style={{ marginBottom: isMobile ? "80px" : "0" }}>
        <SwitchTabs selectedProduct={selectedProduct} />
      </section>
    </Flex>
  );
};

export default ProductInfo;
