import React, { useContext } from "react";
import TopLinks from "../Components/Navbar/TopLinks";
import Navbar from "../Components/Navbar/Navbar";
import WithdrawAmount_Main from "../Components/WithdrawAmount/WithdrawAmount_Main";
import LoadingContext from "../Context/Loading/LoadingContext";

type Props = {
  scrollTop: boolean;
};

const WithdrawAmount = ({ scrollTop }: Props) => {
  const { loading, setLoading } = useContext(LoadingContext);
  return (
    <div style={{ minHeight: "100vh" }}>
      <section>
        <TopLinks />
      </section>
      <Navbar scrollTop={scrollTop} />
      <section
        style={{
          height: loading ? "fit-content" : "86.5vh",
          // height: "fit-content",
          // border: "1px solid red",
          //   position: "relative",
        }}
      >
        <WithdrawAmount_Main />
      </section>
    </div>
  );
};

export default WithdrawAmount;
