import { Box, Flex } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TopLinks from "../Components/Navbar/TopLinks";
import Navbar from "../Components/Navbar/Navbar";
import LoadingContext from "../Context/Loading/LoadingContext";
import SideFilter from "../Components/SideFilter";
import { getParamsValue, searchData } from "../Components/CommonFunction";
import SearchResult from "../Components/SearchPage/SearchResult";
import Footer from "../Components/Footer/Footer";

type Props = {
  scrollTop: boolean;
};

const SearchPage = ({ scrollTop }: Props) => {
  const { id } = useParams();
  const { loading, setLoading } = useContext(LoadingContext);
  const [searchResult, setSearchResult] = useState([]);
  const [filteredResult, setFilteredResult] = useState([]);

  const getSearchData = async () => {
    setLoading(true);
    searchData(
      getParamsValue("category", id) || getParamsValue("q", id),
      setSearchResult
    );
    setLoading(false);
  };

  const applyFilters = (filters: any) => {
    let sortedResult = [...searchResult];

    if (filters.sortOption === "price-low-to-high") {
      sortedResult.sort((a: any, b: any) => a.prices[0].current - b.prices[0].current);
    } else if (filters.sortOption === "price-high-to-low") {
      sortedResult.sort((a: any, b: any) => b.prices[0].current - a.prices[0].current);
    }
    sortedResult = sortedResult.filter((el: any) =>
      filters.priceRange > 99999 ? el : +el.prices[0].current < filters.priceRange
    );

    setFilteredResult(sortedResult);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setFilteredResult(searchResult);
  }, [searchResult]);

  useEffect(() => {
    getSearchData();
    document.title = `ShoppingBoom.in - ${getParamsValue("category", id) || getParamsValue("q", id)}`;
  }, [id]);

  return (
    <Box>
      <TopLinks />
      <Navbar scrollTop={scrollTop} />
      <Flex flexDirection={{ base: "column", md: "row" }} gap={3} px={4}>
        <Box w={{ base: "100%", md: "25%", lg: "20%" }}>
          <SideFilter applyFilters={applyFilters} />
        </Box>
        <Box w={{ base: "100%", md: "75%", lg: "80%" }}>
          <SearchResult
            query={getParamsValue("q", id) || getParamsValue("category", id)}
            result={filteredResult}
            loading={loading}
          />
        </Box>
      </Flex>
      <Footer />
    </Box>
  );
};

export default SearchPage;
