import { Button, Flex, HStack, Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

type Props = {};

const CategoryClick = (props: Props) => {
  const navigate = useNavigate();

  return (
    <HStack
      h={16}
      overflowX={{ base: "scroll", md: "hidden" }}
      px={{ base: 4, md: 80 }}
      css={{
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Flex
        justifyContent={{ base: "flex-start", md: "space-around" }}
        w={"100%"}
        gap={{ base: 4, md: 8 }}
        minW="max-content" 
      >
        {/* Home Button */}
        <Button
          bg={"transparent"}
          _hover={{
            color: "rgba(66,153,225, 0.9)",
            textDecoration: "underline",
          }}
          onClick={() => {
            navigate("/");
          }}
        >
          Home
        </Button>

        {/* Mobiles Category */}
        <Button
          bg={"transparent"}
          _hover={{
            color: "rgba(66,153,225, 0.9)",
            textDecoration: "underline",
          }}
          onClick={() => {
            navigate(`searchProduct/category=Mobiles`);
          }}
        >
          Mobiles
        </Button>

        {/* Electronics Category */}
        <Button
          bg={"transparent"}
          _hover={{
            color: "rgba(66,153,225, 0.9)",
            textDecoration: "underline",
          }}
          onClick={() => {
            navigate("searchProduct/category=Electronics");
          }}
        >
          Electronics
        </Button>

        {/* Accessories Category */}
        <Button
          bg={"transparent"}
          _hover={{
            color: "rgba(66,153,225, 0.9)",
            textDecoration: "underline",
          }}
          onClick={() => {
            navigate("searchProduct/category=Accessories");
          }}
        >
          Accessories
        </Button>

        {/* Additional Categories (if needed) */}
        <Button
          bg={"transparent"}
          _hover={{
            color: "rgba(66,153,225, 0.9)",
            textDecoration: "underline",
          }}
          onClick={() => {
            navigate("searchProduct/category=Fashion");
          }}
        >
          Fashion
        </Button>
        <Button
          bg={"transparent"}
          _hover={{
            color: "rgba(66,153,225, 0.9)",
            textDecoration: "underline",
          }}
          onClick={() => {
            navigate("searchProduct/category=Appliances");
          }}
        >
          Appliances
        </Button>
        <Button
          bg={"transparent"}
          _hover={{
            color: "rgba(66,153,225, 0.9)",
            textDecoration: "underline",
          }}
          onClick={() => {
            navigate("searchProduct/category=Books");
          }}
        >
          Books
        </Button>
      </Flex>
    </HStack>
  );
};

export default CategoryClick;
