import { Box, Flex, SkeletonText, Text } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import LoginContext from "../../Context/Login/LoginContext";
import LoadingContext from "../../Context/Loading/LoadingContext";

type Props = {
  width: string;
  products: any;
  totalSum: number;
  discountedPrice: number;
};

const PriceDetails = ({
  width,
  products,
  totalSum,
  discountedPrice,
}: Props) => {
  const { cartCount } = useContext(LoginContext);
  const { loading } = useContext(LoadingContext);
  return (
    <Box
      // border={"1px solid cyan"}
      bg={"white"}
      borderRadius={"md"}
      w={width}
      // p={3}
    >
      <Text
        textTransform={"uppercase"}
        letterSpacing={3}
        fontWeight={"bold"}
        color={"gray.500"}
        fontSize={"18px"}
        p={3}
        mt={2}
        ml={3}
      >
        Price Details
      </Text>
      <hr style={{ height: "2px", border: "0", borderTop: "2px solid grey" }} />
      <Flex
        // border={"1px solid red"}
        h={"fit-content"}
        // p={3}
        // pt={3}
        // pl={3}
        flexDir={"column"}
        gap={1}
        m={3}
      >
        <Flex justify={"space-between"} p={2}>
          <Text>
            Price (<span style={{ fontSize: "14px" }}>{cartCount} items</span>)
          </Text>
          {loading ? (
            <SkeletonText
              skeletonHeight="5"
              // border={"1px solid red"}
              w={"30%"}
              noOfLines={1}
              h={"fit-content"}
            />
          ) : (
            <Text>
              &#8377;{" "}
              <span style={{ fontSize: "14px" }}>
                {new Intl.NumberFormat("en-IN").format(totalSum)}
              </span>
            </Text>
          )}
        </Flex>
        <Flex justify={"space-between"} p={2}>
          <Text>Discount</Text>
          {loading ? (
            <SkeletonText
              skeletonHeight="5"
              // border={"1px solid red"}
              w={"30%"}
              noOfLines={1}
              h={"fit-content"}
            />
          ) : (
            <Text color={"green.500"}>
              - &#8377;{" "}
              <span style={{ fontSize: "14px" }}>
                {new Intl.NumberFormat("en-IN").format(discountedPrice)}
              </span>
            </Text>
          )}
        </Flex>
        <Flex justify={"space-between"} p={2}>
          <Text>Delivery Charges</Text>
          {loading ? (
            <SkeletonText
              skeletonHeight="5"
              // border={"1px solid red"}
              w={"30%"}
              noOfLines={1}
              h={"fit-content"}
            />
          ) : (
            <Text color={"green.500"}>
              <span style={{ fontSize: "14px" }}>{"Free"}</span>
            </Text>
          )}
        </Flex>
      </Flex>
      <hr style={{ height: "2px", border: "0", borderTop: "2px solid grey" }} />
      <Flex
        // border={"1px solid red"}
        h={"fit-content"}
        // p={3}
        // pt={3}
        // pl={3}
        flexDir={"column"}
        gap={1}
        m={3}
      >
        <Flex justify={"space-between"} fontWeight={"bolder"} p={2}>
          <Text>Total Amount</Text>
          {loading ? (
            <SkeletonText
              skeletonHeight="5"
              // border={"1px solid red"}
              w={"30%"}
              noOfLines={1}
              h={"fit-content"}
            />
          ) : (
            <Text>
              &#8377;{" "}
              <span style={{ fontSize: "14px" }}>
                {new Intl.NumberFormat("en-IN").format(
                  totalSum - discountedPrice
                )}
              </span>
            </Text>
          )}
        </Flex>
      </Flex>
      <hr style={{ height: "2px", border: "0", borderTop: "2px solid grey" }} />
      <Flex
        // border={"1px solid red"}
        h={"fit-content"}
        // p={3}
        // pt={3}
        // pl={3}
        flexDir={"column"}
        gap={1}
        m={3}
      >
        <Flex justify={"center"} color={"green.500"} textAlign={"center"} p={2}>
          <Flex>
            <Text>Total &#8377; </Text>
            <Text>
              {loading ? (
                <SkeletonText
                  skeletonHeight="5"
                  // border={"1px solid red"}
                  w={"50px"}
                  noOfLines={1}
                  h={"fit-content"}
                />
              ) : (
                <>{new Intl.NumberFormat("en-IN").format(discountedPrice)}</>
              )}
            </Text>
            <Text>&nbsp;saved by this order </Text>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default PriceDetails;
