import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Flex,
  Heading,
  Image,
  Skeleton,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { BsCart2 } from "react-icons/bs";
import LoginContext from "../../Context/Login/LoginContext";
import { addToCart, handleProductClick } from "../CommonFunction";

type Props = {
  query: string | null;
  result: any;
  loading: boolean;
};
const rating = () => {
  // Generate a random decimal between 0 (inclusive) and 1 (exclusive)
  const randomDecimal = Math.random();

  // Scale the random decimal to the range 10 to 50 (to get one decimal digit)
  const minRating = 1;
  const maxRating = 5;
  const randomRating = minRating + randomDecimal * (maxRating - minRating);

  // Round the result to one decimal digit
  const finalRating = parseFloat(randomRating.toFixed(1));

  console.log("Random Rating (1 decimal digit):", finalRating);
  return finalRating;
};
const SearchResult = ({ query, result, loading }: Props) => {
  const navigate = useNavigate();
  const { token, currentUser, cartCount, setCartCount } =
    useContext(LoginContext);
  const toast = useToast();

  // State to track whether to display remaining products
  const [showRemainingProducts, setShowRemainingProducts] = useState(false);

  // Function to handle scroll and toggle the state
  const handleScroll = () => {
    const contentContainer = document.getElementById("content");
    if (contentContainer) {
      const scrollPosition = contentContainer.scrollTop;
      const contentHeight = contentContainer.scrollHeight;
      const containerHeight = contentContainer.clientHeight;
      setShowRemainingProducts(
        scrollPosition + containerHeight >= contentHeight
      );
    }
  };

  // Add a scroll event listener when the component mounts
  useEffect(() => {
    const contentContainer = document.getElementById("content");
    if (contentContainer) {
      contentContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      // Remove the event listener when the component unmounts
      if (contentContainer) {
        contentContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <Box
      w={"100%"}
      // border={"1px solid red"}
      mb={2}
    >
      <Flex
        justifyContent={"space-between"}
        w={"100%"}
        p={7}
        fontSize={"22px"}
        boxShadow={"md"}
        position={"sticky"}
        top={16}
        zIndex={2}
        bgColor={"white"}
      >
        <Text>
          Showing Result For "
          <span style={{ color: "red", fontWeight: "700" }}>{query}</span>"
        </Text>
        <Text>
          Total "
          {loading ? (
            <Skeleton w={"10%"} />
          ) : (
            <span style={{ color: "green", fontWeight: "700" }}>
              {result.length}
            </span>
          )}
          " Result
        </Text>
      </Flex>
      <Flex mt={2} flexDir={"column"} gap={2}>
        {loading ? (
          <Text>Loading...</Text>
        ) : (
          result &&
          result.map((el: any, index: number) => (
            <Card
              key={index}
              direction={{ base: "column", sm: "row" }}
              overflow="hidden"
              variant="outline"
              onClick={() => {
                handleProductClick(el, navigate);
              }}
              cursor={"pointer"}
            >
              <Image
                objectFit="cover"
                maxW={{ base: "100px", sm: "200px" }}
                src={el.images[0]}
                alt={el.name}
                p={5}
              />

              <Stack>
                <CardBody>
                  <Heading _hover={{ color: "orange" }} size="md">
                    {el.name}
                  </Heading>
                  <Text mt={3} color={"black"} fontSize={"14px"}>
                    <Badge
                      colorScheme="green"
                      variant="solid"
                      alignSelf={"center"}
                    >
                      {/* Rating is generated randomly make it by database */}
                      {rating()} &#9733;
                    </Badge>{" "}
                    &nbsp;
                    {"300"} Ratings and {"59"} Reviews
                  </Text>
                  <Text fontSize={"20px"} color={"grey"} alignItems={"center"}>
                    <span
                      style={{
                        fontSize: "24px",
                        fontWeight: "bold",
                        color: "green",
                      }}
                    >
                      &#8377;{" "}
                      {new Intl.NumberFormat("en-IN").format(
                        el.prices[0].current
                      )}
                    </span>
                    &nbsp; &nbsp;{" "}
                    <span style={{ fontSize: "14px" }}>
                      M.R.P{" "}
                      <span style={{ textDecoration: "line-through" }}>
                        &#8377;{" "}
                        {new Intl.NumberFormat("en-IN").format(
                          el.prices[0].original
                        )}
                      </span>
                    </span>
                    &nbsp;{" "}
                    <span style={{ color: "green", fontSize: "18px" }}>
                      {el.prices[0].discountPercentage}% Off
                    </span>
                  </Text>
                </CardBody>

                <CardFooter>
                  <Button
                    colorScheme={"orange"}
                    variant={"solid"}
                    onClick={(e) => {
                      e.stopPropagation();
                      console.log(el.id);
                      addToCart(
                        el.id,
                        currentUser.id,
                        token,
                        cartCount,
                        setCartCount,
                        toast
                      );
                    }}
                  >
                    <span>
                      <BsCart2 fontSize={"24px"} />
                    </span>
                    &nbsp; &nbsp; Add to Cart
                  </Button>
                </CardFooter>
              </Stack>
            </Card>
          ))
        )}
      </Flex>

      {/* Conditionally render the remaining products from the API response */}
      {showRemainingProducts && (
        <Flex mt={2} flexDir={"column"} gap={2}>
          {result &&
            result.map((el: any, index: number) => (
              <Card
                key={index}
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                variant="outline"
                onClick={() => {
                  handleProductClick(el, navigate);
                }}
                cursor={"pointer"}
              >
                {/* ... (same JSX code for rendering products as above) */}
              </Card>
            ))}
        </Flex>
      )}
    </Box>
  );
};

export default SearchResult;
