import React, { useState, useEffect } from "react";
import LoginContext from "./LoginContext";
import { useCookies } from "react-cookie";
import { authInstance } from "../../Axios/Axios";

type Props = {
  children: React.ReactNode;
};

let currentUser: any = [];
// let cartCount = 0;

const tokenValid = async (token: string, setCartCount: any) => {
  try {
    let res = await authInstance.post(`/verify-token`, { token: token });
    if (res.status === 200 && res.data.success === true) {
      setCartCount(res.data.cartCount);
      currentUser = res.data.Data[0];
      // console.log("response is ", currentUser);
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

const LoginState = (props: Props) => {
  const [loginState, setLoginState] = useState<boolean>(false); // Changed to boolean
  const [cookies] = useCookies(); // Removed 'setCookie' and 'removeCookie'
  const [cartCount, setCartCount] = useState(0);
  let token = cookies.token;

  const fetchLoginState = async () => {
    if (token) {
      try {
        const isValid = await tokenValid(token, setCartCount);
        console.log(cartCount);
        if (isValid) {
          setLoginState(isValid);
        } else {
          setLoginState(false);
        }
      } catch (error) {
        console.error("Error checking token validity:", error);
        setLoginState(false);
      }
    } else {
      setLoginState(false);
    }
  };
  useEffect(() => {
    fetchLoginState();
  }, [token]);

  return (
    <LoginContext.Provider
      value={{
        loginState,
        setLoginState,
        currentUser,
        cartCount,
        token,
        setCartCount,
      }}
    >
      {props.children}
    </LoginContext.Provider>
  );
};

export default LoginState;
