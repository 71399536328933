import { Flex } from "@chakra-ui/react";
import SingleProductCard from "./SingleProductCard";

type Props = {
  product: any;
};

const ProductsOverflowX = ({ product }: Props) => {
  return (
    <Flex
      overflowX="auto"
      width="100%"
      height="100%"
      alignItems="center"
      flexWrap="nowrap"
    >
      {product &&
        product.map((el: any, index: number) => (
          <SingleProductCard el={el} index={index} key={index} />
        ))}
    </Flex>
  );
};

export default ProductsOverflowX;
