import {
  Box,
  Button,
  Flex,
  Text,
  RadioGroup,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import TopLinks from "../Components/Navbar/TopLinks";
import Navbar from "../Components/Navbar/Navbar";
import LoginContext from "../Context/Login/LoginContext";
import { useNavigate, useParams } from "react-router-dom";
import LoadingContext from "../Context/Loading/LoadingContext";
import { authInstance } from "../Axios/Axios";
import { buySingleProduct, getParamsValue } from "../Components/CommonFunction";
import SelectedAddressDetails from "../Components/Address/SelectedAddressDetails";
import AddressSelector from "../Components/Address/AddressSelector";
import AddAddressModal from "../Components/Address/AddAddressModal"; // Modal Component
import AuthToast from "../Components/Toast";

type Address = {
  AddressLine1: string;
  IsDefault: number;
  [key: string]: any; // Additional fields
};

type Props = {
  scrollTop: boolean;
};

const Checkout = ({ scrollTop }: Props) => {
  const { token, loginState } = useContext(LoginContext);
  const { loading } = useContext(LoadingContext);
  const [userAddress, setUserAddress] = useState<Address[]>([]);
  const [addressCount, setAddressCount] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);

  const navigate = useNavigate();
  const { id, pId } = useParams();
  let userId = getParamsValue("id", id);
  let productId = getParamsValue("pId", pId);
  let defaultAddId: any;
  // console.log(productId);
  const headers = { Authorization: `Bearer ${token}` };
  const config = { headers: headers };

  // Modal control
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const getAddress = async () => {
    const res = await authInstance.get(`/getAddress/${userId}`, config);
    const addresses = res?.data?.message;
    setUserAddress(addresses);
    setAddressCount(addresses.length);
    const defaultAddress = addresses.find(
      (address: any) => address.IsDefault === 1
    );
    // defaultAddId = defaultAddress;
    console.log(defaultAddress);
    setSelectedAddress(defaultAddress.id || null);
  };
  const handleCheckoutClick = async () => {
    // setSelectedAddress(defaultAddId);
    console.log(selectedAddress);
    buySingleProduct(
      userId,
      productId,
      selectedAddress,
      toast,
      config,
      navigate
    );
    // response
    //   ? AuthToast("", toast, "Order Placed Successfully", "success")
    //   : AuthToast("", toast, "Something Went Wrong", "error");
  };

  const handleAddNewAddress = async (newAddress: Address) => {
    console.log(newAddress);
    let res = await authInstance.post(
      `/addAddress/${userId}`,
      newAddress,
      config
    );
    console.log(res);
    if (res.status === 200) {
      setAddressCount(addressCount + 1);
    }
    onClose(); // Close modal after adding
  };

  useEffect(() => {
    getAddress();
  }, [addressCount]);

  return (
    <Box minH={"100vh"} w={"100%"}>
      <section>
        <TopLinks />
      </section>
      <Navbar scrollTop={scrollTop} />
      {loginState ? (
        <Box>
          <section
            style={{
              height: loading ? "fit-content" : "75vh",
              position: "relative",
            }}
          >
            <Flex
              p={3}
              fontSize={{ base: "20px", md: "32px" }} // Responsive font size
              position={"sticky"}
              top={0}
              mt={3}
              h={"15%"}
              bg={"white"}
              boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
              borderRadius={"md"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Text as={"b"}>Checkout</Text>
            </Flex>
            <Flex
              gap={3}
              p={3}
              h={"auto"}
              flexDirection={{ base: "column", md: "row" }}
            >
              {/* Address Selection Box */}
              <Box
                width={{ base: "100%", md: "70%" }}
                bg={"white"}
                borderRadius={"lg"}
                p={3}
              >
                <Text
                  align={"center"}
                  fontSize={{ base: "18px", md: "22px" }}
                  mb={4}
                >
                  Select Address
                </Text>
                <Button
                  colorScheme={"blue"}
                  onClick={onOpen}
                  mb={2}
                  size={"sm"}
                >
                  Add New Address
                </Button>
                <Flex
                  h={"80%"}
                  alignItems={"center"}
                  justify={"center"}
                  overflowY={"auto"}
                >
                  {userAddress.length === 0 ? (
                    <Text fontSize={"20px"}>No Recent Address Saved</Text>
                  ) : (
                    <AddressSelector
                      userAddress={userAddress}
                      selectedAddress={selectedAddress}
                      setSelectedAddress={setSelectedAddress}
                    />
                  )}
                </Flex>
              </Box>

              {/* Selected Address Details */}
              <Box
                width={{ base: "100%", md: "30%" }}
                h={"fit-content"}
                p={4}
                bg={"gray.100"}
                borderRadius={"md"}
              >
                <SelectedAddressDetails
                  userAddress={userAddress}
                  selectedAddress={selectedAddress}
                />
              </Box>
            </Flex>
          </section>
          <section>
            <Flex
              p={3}
              w={"full"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Flex
                bg={"white"}
                w={"full"}
                justifyContent={"space-between"}
                h={"250px"}
                gap={5}
                p={3}
                borderRadius={"md"}
                boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
              >
                <Button
                  colorScheme={"green"}
                  size={"lg"}
                  onClick={() => handleCheckoutClick()}
                  isDisabled={!selectedAddress}
                >
                  Proceed To Checkout
                </Button>
              </Flex>
            </Flex>
          </section>
          <AddAddressModal
            isOpen={isOpen}
            onClose={onClose}
            handleAddNewAddress={handleAddNewAddress}
          />
        </Box>
      ) : (
        <Flex h={"50vh"} p={5} justify={"center"} alignItems={"center"}>
          <Flex
            bg={"white"}
            w={{ base: "100%", md: "80%" }}
            borderRadius={"lg"}
            justifyContent={"center"}
          >
            <Text>Login Required</Text>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default Checkout;
