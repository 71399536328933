import React, { useEffect, useRef, useState } from "react";
import Navbar from "../Components/Navbar/Navbar";
import TopLinks from "../Components/Navbar/TopLinks";
import Banner from "../Components/Dashboard/Banner";
import ProductShow from "../Components/Dashboard/ProductShow";
import CategoryClick from "../Components/Navbar/CategoryClick";
import { Box, Heading } from "@chakra-ui/react";
import Footer from "../Components/Footer/Footer";
import { authInstance } from "../Axios/Axios";

type Props = {
  scrollTop: boolean;
  // product: any;
};

const Dashboard = ({ scrollTop }: Props) => {
  const [product, setProduct] = useState([]);
  const productData1 = async () => {
    let res: any = await authInstance.get("products");
    res = await res;
    console.log(res.data.data);
    setProduct(res.data.data);
    // return res.data.data;
  };
  useEffect(() => {
    productData1();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.href]);
  document.title = `India's Multilevel Marketing E-Commerce Platform : Shop from Us Earn With Us`;
  console.log(product);

  return (
    <div>
      <section>
        <TopLinks />
      </section>
      <Navbar scrollTop={scrollTop} />
      <section>
        <CategoryClick />
      </section>
      <section>
        <Banner />
      </section>
      {/* Product Sections */}
      {["New at ShoppingBoom", "Top in Mobiles"].map((title, index) => (
        <Box key={index} px={{ base: 4, md: 10 }} py={4}>
          <Heading
            as="h3"
            size="lg"
            mb={4}
            className="underline-animation"
            textAlign="center"
          >
            {title}
          </Heading>
          <ProductShow product={product} />
        </Box>
      ))}

      <Footer />
    </div>
  );
};

export default Dashboard;
