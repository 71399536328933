import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import TopLinks from "../Components/Navbar/TopLinks";
import Navbar from "../Components/Navbar/Navbar";
import ProductsDetails from "../Components/Cart/ProductsDetails";
import PriceDetails from "../Components/Cart/PriceDetails";
import { authInstance } from "../Axios/Axios";
import LoginContext from "../Context/Login/LoginContext";
import LoadingContext from "../Context/Loading/LoadingContext";
import { getParamsValue } from "../Components/CommonFunction";
import error from "../Assets/Error.png";

type Props = { scrollTop: boolean };

const Cart = ({ scrollTop }: Props) => {
  const [cartData, setCartData] = useState([]);
  const { currentUser, token, cartCount, loginState } =
    useContext(LoginContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const { loading, setLoading } = useContext(LoadingContext);
  const [totalSum, setTotalSum] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [discount, setDiscount] = useState(0);

  const isMobile = useBreakpointValue({ base: true, md: false });

  const headers = { Authorization: `Bearer ${token}` };
  const config = { headers };

  const cartDataApi = async () => {
    setLoading(true);
    try {
      let res = await authInstance.get(
        `cart/items/${getParamsValue("id", id)}`,
        config
      );
      setCartData(res.data.result);
    } catch (error) {
      console.error("Error fetching cart data:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (loginState) {
      cartDataApi();
      document.title = `ShoppingBoom.in @${currentUser.name}'s Cart`;
    } else {
      document.title = `ShoppingBoom.in Guest's Cart`;
    }
  }, [currentUser, cartCount]);

  return (
    <Box minH="100vh">
      <TopLinks />
      <Navbar scrollTop={scrollTop} />

      {loginState ? (
        <Box>
          {/* Responsive Cart Layout */}
          <Flex direction={isMobile ? "column" : "row"} p={3} gap={3}>
            {/* Products List */}
            <Box flex={2} w="100%">
              <ProductsDetails
                totalSum={totalSum}
                setTotalSum={setTotalSum}
                discountedPrice={discountedPrice}
                setDiscountedPrice={setDiscountedPrice}
                discount={discount}
                setDiscount={setDiscount}
                products={cartData}
                currentUser={currentUser}
                width={"100%"}
              />
            </Box>

            {/* Price Summary */}
            <Box flex={1} w="100%">
              <PriceDetails
                totalSum={totalSum}
                discountedPrice={discountedPrice}
                products={cartData}
                width={"100%"}
              />
            </Box>
          </Flex>

          {/* Checkout Button */}
          <Flex p={3} w={{ base: "100%", md: "70%" }} justify="center">
            <Button
              colorScheme="green"
              size="lg"
              w="full"
              maxW="300px"
              onClick={() => navigate("/checkout")}
            >
              Proceed To Checkout
            </Button>
          </Flex>
        </Box>
      ) : (
        // If User Not Logged In
        <Flex h="50vh" p={5} justify="center" align="center">
          <Flex
            bg="white"
            w={{ base: "90%", md: "70%" }}
            borderRadius="lg"
            flexDirection={isMobile ? "column" : "row"}
            align="center"
          >
            <Image src={error} boxSize={{ base: "100px", md: "150px" }} />
            <Flex flexDir="column" textAlign="center" p={5} gap={5}>
              <Text as="b" fontSize={{ base: "20px", md: "28px" }}>
                User Cart is Empty!!!
              </Text>
              <Button
                colorScheme="yellow"
                onClick={() => navigate("/login?source=cart")}
              >
                Login
              </Button>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default Cart;
