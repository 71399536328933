import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Box } from "@chakra-ui/react";
import AuthTemplate from "./Components/Authentication/AuthTemplate";
import AllRoutes from "./Router/AllRoutes";

function App() {
  return (
    <Box h={"fit-content"} w={"100vw"} bg={"#ededed"}>
      <AllRoutes />
    </Box>
  );
}

export default App;

// below code is for image to base64 String convertor

// import React, { useEffect, useState } from "react";

// const App = () => {
//   const [base64Image, setBase64Image] = useState<string | null>(null); // State to store the Base64 image data

//   // Function to handle image selection and conversion to Base64
//   const handleImageSelection = (event: any) => {
//     const file = event.target.files[0];
//     const reader = new FileReader();
//     //   // Event handler when the FileReader finishes reading the file
//     reader.onload = function (event) {
//       // console.log(event.target?.result?.toString());
//       const result = event.target?.result;
//       if (result) {
//         // Check if result is not null or undefined
//         const base64String =
//           typeof result === "string" ? result : result.toString();
//         setBase64Image(base64String); // Update the state with the Base64 image data
//       }
//     };
//     //   // Read the file as data URL (Base64)
//     reader.readAsDataURL(file);
//   };
//   useEffect(() => {
//     console.log(base64Image);
//   }, [base64Image]);
//   return (
//     <div>
//       {/* HTML input element to choose an image */}
//       <input type="file" onChange={handleImageSelection} />

//       {/* Display the converted Base64 image */}
//       {base64Image && <img src={base64Image} alt="Base64 Image" />}
//     </div>
//   );
// };

// export default App;
