import React, { useContext, useRef, useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightAddon,
  InputRightElement,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure,
  useToast,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import logo from "../../Assets/BoomLogo.png";
import maleImg from "../../Assets/MaleUser.jpg";
import femaleImg from "../../Assets/FemaleUser.jpg";
import { BsCart2 } from "react-icons/bs";
import { motion } from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import { BellIcon, HamburgerIcon, SearchIcon } from "@chakra-ui/icons";
import LoginContext, {
  LoginContextType,
} from "../../Context/Login/LoginContext";
import { relative } from "path";
import {
  getParamsValue,
  handleCartClick,
  handleProductClick,
  searchData,
} from "../CommonFunction";
import { useCookies } from "react-cookie";
import AuthToast from "../Toast";

type Props = {
  scrollTop: boolean;
};

const searchResultData = [
  {
    id: 1,
    name: "Apple Macbook Air M2",
    category: "Laptop",
    images: [
      "https://rukminim2.flixcart.com/image/832/832/xif0q/computer/2/v/v/-original-imagfdeqter4sj2j.jpeg?q=70",
    ],
  },
  {
    id: 2,
    name: "Apple iPhone 14",
    category: "Mobile",
    images: [
      "https://rukminim2.flixcart.com/image/832/832/xif0q/mobile/m/o/b/-original-imaghx9qkugtbfrn.jpeg?q=70",
    ],
  },
  {
    id: 3,
    name: "Apple iWatch7",
    category: "Watch",
    images: [
      "https://rukminim2.flixcart.com/image/832/832/ku8pbbk0/smartwatch/x/w/8/ios-mkhw3hn-a-apple-yes-original-imag7eqypz9zkbn3.jpeg?q=70",
    ],
  },
  {
    id: 4,
    name: "Bajaj Iron",
    category: "Electornics",
    images: [
      "https://rukminim2.flixcart.com/image/832/832/k3xcdjk0pkrrdj/iron-refurbished/a/p/7/c-popular-1000-watts-bajaj-original-imafmz9hheftzvev.jpeg?q=70",
    ],
  },
  {
    id: 5,
    name: "Neet Books",
    category: "Study Material",
    images: [
      "https://rukminim2.flixcart.com/image/832/832/xif0q/book/q/n/d/smart-errorless-physics-neet-class-11-2024-ncert-based-4000-original-imagzu9q3az8zfyv.jpeg?q=70",
    ],
  },
  {
    id: 6,
    name: "Tomato",
    category: "Groceries",
    images: [
      "https://rukminim2.flixcart.com/image/832/832/kr58yvk0/vegetable/b/k/n/1-tomato-local-red-un-branded-no-whole-original-imag5y68d3uxjyza.jpeg?q=70",
    ],
  },
];
const Navbar = ({ scrollTop }: Props) => {
  const { id } = useParams();
  const sText = getParamsValue("category", id) || getParamsValue("q", id);
  const [searchText, setSearchText] = useState(sText || "");
  const [searchResult, setSearchResult] = useState(searchResultData);
  const [searchActive, setSearchActive] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const toast = useToast();
  const [cookies, setCookie, removeCookie] = useCookies();
  const { loginState, currentUser, cartCount } =
    useContext<LoginContextType>(LoginContext);
  const navRef = useRef<HTMLDivElement>(null);
  if (scrollTop === false && navRef.current) {
    // console.log(navRef);
    navRef.current.style.position = "sticky";
    navRef.current.style.top = "0";
    navRef.current.style.zIndex = "1000";
  }
  const handleSearch = () => {
    if (searchText.trim() !== "") {
      navigate(`/searchProduct/q=${searchText}`);
    }
  };
  // if (searchText !== "") {
  //   setSearchActive(true);
  // } else if (searchText === "") {
  //   setSearchActive(false);
  // }
  // console.log(searchText);
  return (
    <Box ref={navRef}>
      {/* Navbar Container */}
      <Flex
        bg="gray.500"
        w="100%"
        h="16"
        align="center"
        justify="space-between"
        px={4}
        color="white"
      >
        {/* Logo or Branding */}
        <Text
          fontSize="xl"
          fontWeight="bold"
          cursor="pointer"
          display={{ base: "none", md: "block" }}
          onClick={() => navigate("/")}
        >
          ShoppingBoom
        </Text>

        {/* Mobile Hamburger Icon */}
        <IconButton
          display={{ base: "block", md: "none" }}
          icon={<HamburgerIcon />}
          aria-label="Menu"
          onClick={onOpen}
        />

        {/* Search Bar */}
        <InputGroup w={{ base: "70%", md: "30%" }}>
          <Input
            placeholder="Search ShoppingBoom.in"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
          />
          <InputRightElement>
            <IconButton
              icon={<SearchIcon />}
              aria-label="Search"
              onClick={handleSearch}
              bg="transparent"
              _hover={{ bg: "transparent" }}
            />
          </InputRightElement>
          {searchActive ? (
            <Box
              minH={"fit-content"}
              maxH={"300px"}
              h={"300px"}
              w={"100%"}
              overflowY={"auto"}
              position={"absolute"}
              top={10}
              // border={"1px solid black"}
              bg={"white"}
              borderRadius={"md"}
              // borderCollapse={"collapse"}
            >
              {searchText === ""
                ? ""
                : searchResult &&
                  searchResult.map((el) => (
                    <Card
                      borderBottom={"0.5px solid grey"}
                      h={"80px"}
                      bg={"transparent"}
                      direction={{ base: "row" }}
                      gap={3}
                      cursor={"pointer"}
                      onClick={() => {
                        handleProductClick(el, navigate);
                      }}
                      _hover={{
                        bg: "rgba(211,211,211,0.60 )",
                        transition: "background-color 0.4s, transform 5s", // Adding transition properties,
                      }}
                      key={el.id}
                    >
                      <HStack
                        h={"100%"}
                        width={"15%"}
                        // border={"1px solid red"}
                        p={2}
                        alignItems="center"
                        verticalAlign={"middle"}
                      >
                        <Image
                          src={el.images[0]}
                          h={""}
                          w={"100%"}
                          // border={"1px solid red"}
                        />
                      </HStack>
                      <Box>
                        <Text fontSize={"18px"} as={"b"}>
                          {el.name}
                        </Text>
                        <Text fontSize={"14px"} color={"blue.500"}>
                          {" "}
                          in {el.category}
                        </Text>
                      </Box>
                    </Card>
                  ))}
            </Box>
          ) : (
            <></>
          )}
        </InputGroup>

        {/* Desktop Menu */}
        <HStack spacing={6} display={{ base: "none", md: "flex" }}>
          <Button
            bg="transparent"
            onClick={() => navigate("/sell")}
            _hover={{ bg: "gray.600" }}
          >
            Sell Your Product
          </Button>

          <Button
            bg="transparent"
            color="white"
            _hover={{ bg: "none" }}
            onClick={() => {
              handleCartClick(navigate, currentUser.id, loginState);
            }}
          >
            <Stack direction="row" alignItems="center">
              <Stack position="relative">
                <BsCart2 fontSize="1.5rem" />
                {cartCount > 0 ? (
                  <Badge
                    position="absolute"
                    top="-8px"
                    right="-8px"
                    bg="red.500"
                    color="white"
                    h="20px"
                    w="20px"
                    textAlign="center"
                    borderRadius="50%"
                    fontSize="10px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {cartCount}
                  </Badge>
                ) : (
                  <></>
                )}
              </Stack>
            </Stack>
            &nbsp; Cart
          </Button>

          {/* User Profile or Login */}
          {loginState ? (
            <Menu>
              <MenuButton>
                <Wrap>
                  <WrapItem>
                    <Avatar
                      name={`${currentUser?.name || ""}`}
                      src={
                        currentUser?.profileImg ||
                        (currentUser?.gender === "male" ? maleImg : femaleImg)
                      }
                    />
                  </WrapItem>
                </Wrap>
              </MenuButton>
              <MenuList zIndex={10} color={"black"}>
                <MenuGroup title={`Hi, ${currentUser?.name}`}>
                  <MenuItem
                    onClick={() => {
                      navigate(`/profile/id=${currentUser.id}`);
                    }}
                  >
                    Profile
                  </MenuItem>
                  <MenuItem onClick={() => console.log("Orders clicked")}>
                    Orders
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      removeCookie("token");
                      window.location.reload();
                      AuthToast("", toast, "User Logout!", "warning");
                    }}
                  >
                    Logout
                  </MenuItem>
                </MenuGroup>
                <MenuDivider />
                <MenuGroup title="Your Network">
                  <MenuItem>Binary Tree</MenuItem>
                  <MenuItem>Referred Users</MenuItem>
                  <MenuItem>Marketing Level</MenuItem>
                </MenuGroup>
              </MenuList>
            </Menu>
          ) : (
            <Button onClick={() => navigate("/login")}>Login</Button>
          )}
        </HStack>
      </Flex>

      {/* Drawer for Mobile Menu */}
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>ShoppingBoom</DrawerHeader>
          <DrawerBody>
            <Stack spacing={2} border={"1px solid red"}>
              <Button
                bg="transparent"
                onClick={() => {
                  navigate("/sell");
                  onClose();
                }}
              >
                Sell Your Product
              </Button>

              <Button
                bg="transparent"
                onClick={() => {
                  navigate(`/cart/id=${currentUser?.id || ""}`);
                  onClose();
                }}
              >
                <Stack direction="row" alignItems="center">
                  <Flex
                    alignItems={"center"}
                    // border={"1px solid red"}
                    gap={2}
                    position={"relative"}
                    p={2}
                  >
                    <BsCart2 fontSize="1.5rem" />
                    Cart
                    {cartCount > 0 && (
                      <Badge
                        bg="red.500"
                        color="white"
                        ml="2"
                        h="20px"
                        w="20px"
                        textAlign="center"
                        borderRadius="50%"
                        fontSize="10px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        position={"absolute"}
                        top={0}
                        right={-2}
                      >
                        {cartCount}
                      </Badge>
                    )}
                  </Flex>
                </Stack>
              </Button>

              {loginState ? (
                <VStack spacing={4} align="stretch">
                  <Box
                    as="button"
                    fontSize="lg"
                    fontWeight="medium"
                    onClick={() => {
                      navigate(`/profile/id=${currentUser.id}`);
                    }}
                  >
                    Profile
                  </Box>
                  <Box
                    as="button"
                    fontSize="lg"
                    fontWeight="medium"
                    onClick={() => console.log("Orders clicked")}
                  >
                    Orders
                  </Box>
                  <Box
                    as="button"
                    fontSize="lg"
                    fontWeight="medium"
                    color="red.500"
                    onClick={() => {
                      removeCookie("token");
                      window.location.reload();
                      AuthToast("", toast, "User Logout!", "warning");
                    }}
                  >
                    Logout
                  </Box>
                </VStack>
              ) : (
                <Button
                  onClick={() => {
                    navigate("/login");
                    onClose();
                  }}
                >
                  Login
                </Button>
              )}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Navbar;
