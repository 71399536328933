import {
  Box,
  Text,
  Button,
  Input,
  VStack,
  Flex,
  Badge,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaRegClipboard } from "react-icons/fa";
import { copyToClipboard } from "../CommonFunction";

type Props = {
  currentUser: any;
  token: string;
};
const UserDetails = ({ currentUser, token }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [userData, setUserData] = useState({
    name: currentUser.name,
    email: currentUser.email,
    mobile: currentUser.mobile,
    gender: currentUser.gender,
    password: currentUser.password,
    status: currentUser.status,
    KYC: currentUser.KYC,
    parentId: currentUser.referBy,
    referId: currentUser.referId,
    leftCount: currentUser.leftCount,
    rightCount: currentUser.rightCount,
  });
  const toast = useToast();
  const handleChange = (e: any) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    setIsEditing(false);
    console.log("Updated Data:", userData);
  };
  useEffect(() => {
    setUserData({
      name: currentUser.name,
      email: currentUser.email,
      mobile: currentUser.mobile,
      gender: currentUser.gender,
      status: currentUser.status,
      KYC: currentUser.KYC,
      password: currentUser.password,
      parentId: currentUser.referby,
      referId: currentUser.referId,
      leftCount: currentUser.leftCount,
      rightCount: currentUser.rightCount,
    });
  }, [currentUser]);
  return (
    <Box p={4} border="1px solid #ddd" borderRadius="lg">
      <Text fontSize="lg" fontWeight="bold">
        Personal Information
      </Text>
      <VStack spacing={2} mt={3} align="stretch">
        {isEditing ? (
          <>
            {" "}
            <Text>Name: </Text>
            <Input name="name" value={userData.name} onChange={handleChange} />
            <Text>Email:</Text>
            <Input
              name="email"
              value={userData.email}
              onChange={handleChange}
            />
            <Text>Mobile: </Text>
            <Input
              name="mobile"
              value={userData.mobile}
              onChange={handleChange}
            />
            <Text>Gender: </Text>
            <Input
              name="gender"
              value={userData.gender}
              onChange={handleChange}
            />
            <Text>Password: </Text>
            <Input
              name="password"
              type="password"
              value={userData.password}
              onChange={handleChange}
            />{" "}
            <Text>Parent ID: </Text>
            <Input
              name="parentId"
              value={userData.parentId}
              onChange={handleChange}
              isReadOnly
            />
            <Text>Refer ID:</Text>
            <Input
              name="referId"
              value={userData.referId}
              onChange={handleChange}
              isReadOnly
            />
            <Button colorScheme="blue" onClick={handleSave}>
              Save
            </Button>
          </>
        ) : (
          <>
            <Text>Name: {userData.name}</Text>
            <Text>Email: {userData.email}</Text>
            <Text>Mobile: {userData.mobile}</Text>
            <Text>Gender: {userData.gender}</Text>
            <Flex align="center" gap={2}>
              <Text>Status:</Text>
              <Badge
                colorScheme={userData.status === "active" ? "green" : "red"}
                variant="solid"
              >
                {userData.status}
              </Badge>
            </Flex>
            <Flex justify={"space-between"}>
              <Flex align="center" gap={2}>
                <Text>KYC:</Text>
                <Badge
                  colorScheme={userData.status === true ? "green" : "red"}
                  variant="solid"
                >
                  {userData.KYC ? "Completed" : "Pending"}
                </Badge>
              </Flex>
              <Flex>
                <Button colorScheme={"green"} isDisabled>
                  Complete KYC Now
                </Button>
              </Flex>
            </Flex>
            <Text>Password: ••••••••</Text>
            <Text>Parent ID: {userData.parentId}</Text>
            <Flex align="center" gap={2}>
              <Text>Refer ID: {userData.referId}</Text>
              <Text
                cursor={"pointer"}
                onClick={() => {
                  copyToClipboard(userData.referId, toast);
                }}
              >
                <FaRegClipboard />
              </Text>
            </Flex>
            <Text>Left Users: {userData.leftCount}</Text>
            <Text>Right Users: {userData.rightCount}</Text>
            <Button
              isDisabled
              colorScheme="blue"
              onClick={() => setIsEditing(true)}
            >
              Edit
            </Button>
          </>
        )}
      </VStack>
    </Box>
  );
};

export default UserDetails;
