import React from "react";
import {
  Swiper as OriginalSwiper,
  SwiperSlide,
  SwiperProps,
} from "swiper/react";
import SwiperCore from "swiper";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import "swiper/css"; // Import Swiper base styles
import "swiper/css/navigation"; // Import Swiper navigation styles
import "swiper/css/pagination"; // Import Swiper pagination styles
import "swiper/css/effect-fade"; // Import Swiper effect-fade styles
import { Image } from "@chakra-ui/react";

type DataObject = {
  image: string;
};

type Props = {
  dataArray: DataObject[];
  style: React.CSSProperties;
};

type CustomSwiperProps = SwiperProps & {
  modules: any[];
  spaceBetween: number;
  slidesPerView: number;
  navigation: any;
  pagination: any;
  // scrollbar: any;
  // onSwiper: any;
  // onSlideChange: any;
  effect: any;
  autoplay: any;
};
const Swiper = (props: CustomSwiperProps) => {
  const { modules, ...rest } = props;

  return <OriginalSwiper {...rest} />;
};

SwiperCore.use([Autoplay, EffectFade, Navigation, Pagination]);

const Carousel: React.FC<Props> = ({ dataArray, style }) => {
  const swiperParams = {
    modules: [Autoplay, Navigation, Pagination, EffectFade],
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    spaceBetween: 30,
    slidesPerView: 1,
    effect: "fade", // Ensures the fade effect is applied
    navigation: true,
    pagination: { clickable: true },
    loop: true,
  };

  return (
    <Swiper {...swiperParams}>
      {dataArray.map((image, index) => (
        <SwiperSlide key={index}>
          <Image src={image.image} alt={`Slide ${index + 1}`} style={style} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Carousel;
