import { Box, Flex, Text, Button, VStack } from "@chakra-ui/react";
import { useContext, useState } from "react";
import UserDetails from "../Components/UserProfile/UserDetails";
import Wallet from "../Components/UserProfile/Wallet";
import Earnings from "../Components/UserProfile/Earnings";
import ReferralLink from "../Components/UserProfile/ReferralLink";
import TopLinks from "../Components/Navbar/TopLinks";
import Navbar from "../Components/Navbar/Navbar";
import LoginContext from "../Context/Login/LoginContext";

type Props = {
  scrollTop: boolean;
  // product: any;
};
const UserProfile = ({ scrollTop }: Props) => {
  const { token, currentUser } = useContext(LoginContext);
  return (
    <Box>
      <section>
        <TopLinks />
      </section>
      <Navbar scrollTop={scrollTop} />
      <Box p={5} maxW="900px" mx="auto">
        <Text fontSize="2xl" fontWeight="bold" mb={4}>
          User Profile
        </Text>
        <VStack spacing={5} align="stretch">
          <UserDetails currentUser={currentUser} token={token} />
          <Wallet currentUser={currentUser} token={token} />
          <Earnings currentUser={currentUser} token={token} />
          <ReferralLink currentUser={currentUser} token={token} />
        </VStack>
      </Box>
    </Box>
  );
};

export default UserProfile;
