import React from "react";

type Props = {
  selectedProduct: any;
};

const Specification = ({ selectedProduct }: Props) => {
  return <div>Specification</div>;
};

export default Specification;
