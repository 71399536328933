import { authInstance } from "../Axios/Axios";

export const DashboardBanner = [
  {
    image:
      "https://rukminim2.flixcart.com/fk-p-flap/844/140/image/0ec99b461d4e3de8.jpg?q=100",
  },
  {
    image:
      "https://rukminim1.flixcart.com/fk-p-flap/1600/270/image/aa3ffec33d25334b.png?q=100",
  },
  {
    image:
      "https://rukminim2.flixcart.com/image/200/200/knyxqq80/dslr-camera/r/y/x/digital-camera-eos-m50-mark-ii-eos-m50-mark-ii-canon-original-imag2gzkexzqhyhu.jpeg?q=100",
  },
];



export const productData = [
  {
    id: 1,
    name: "Ipad",
    price: 1499.0, // Price in INR
    category: "Electronics",
    description: "This is the description of Product 1.",
    imageUrl: [
      "https://rukminim2.flixcart.com/image/200/200/ktop5e80/tablet/x/9/o/mk2k3hn-a-apple-original-imag6yy7xjjugz4w.jpeg?q=70",
      "https://rukminim2.flixcart.com/image/200/200/ktop5e80/tablet/x/9/o/mk2k3hn-a-apple-original-imag6yy7xjjugz4w.jpeg?q=70",
      "https://rukminim2.flixcart.com/image/200/200/l5jxt3k0/dslr-camera/m/n/a/-original-imagg7hsggshhwbz.jpeg?q=70",
      "https://rukminim2.flixcart.com/image/200/200/l5jxt3k0/dslr-camera/m/n/a/-original-imagg7hsggshhwbz.jpeg?q=70",
      "https://rukminim2.flixcart.com/image/200/200/l5jxt3k0/dslr-camera/m/n/a/-original-imagg7hsggshhwbz.jpeg?q=70",
      "https://rukminim2.flixcart.com/image/200/200/l5jxt3k0/dslr-camera/m/n/a/-original-imagg7hsggshhwbz.jpeg?q=70",
    ],
  },
  {
    id: 2,
    name: "Cameras",
    price: 2199.0, // Price in INR
    category: "Clothing",
    description: "This is the description of Product 2.",
    imageUrl: [
      "https://rukminim2.flixcart.com/image/200/200/l5jxt3k0/dslr-camera/m/n/a/-original-imagg7hsggshhwbz.jpeg?q=70",
    ],
  },
  {
    id: 3,
    name: "Apple 20W power adapter",
    price: 699.0, // Price in INR
    category: "Home & Kitchen",
    description: "This is the description of Product 3.",
    imageUrl: [
      "https://rukminim2.flixcart.com/image/200/200/kgqvlow0/battery-charger/d/a/7/apple-mhjd3hn-a-original-imafwwwfg5xgcctm.jpeg?q=70",
    ],
  },
  {
    id: 4,
    name: "Apple 1st Generation Pencil",
    price: 3199.0, // Price in INR
    category: "Beauty",
    description: "This is the description of Product 4.",
    imageUrl: [
      "https://rukminim2.flixcart.com/image/200/200/xif0q/stylus/6/t/u/pencil-1st-generation-mqly3zm-a-apple-original-imagj85yqajmxu9j.jpeg?q=70",
    ],
  },
  {
    id: 5,
    name: "Camera Bag",
    price: 4099.0, // Price in INR
    category: "Sports",
    description: "This is the description of Product 5.",
    imageUrl: [
      "https://rukminim2.flixcart.com/image/200/200/kox8b680/camera-bag/backpack/m/q/o/dslr-slr-camera-lens-shoulder-backpack-case-for-canon-nikon-original-imag39xgsm4py2hq.jpeg?q=70",
    ],
  },
];
