import {
  Box,
  Button,
  Flex,
  Image,
  Skeleton,
  useToast,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { BsCart2 } from "react-icons/bs";
import { AiOutlineThunderbolt } from "react-icons/ai";
import { addToCart, buyNowClickHandler } from "../CommonFunction";
import LoginContext from "../../Context/Login/LoginContext";
import LoadingContext from "../../Context/Loading/LoadingContext";
import { useNavigate } from "react-router-dom";
import Carousel from "../Carousel"; // Import Swiper Carousel component

type Props = {
  selectedProduct: any;
};

const ImageView = ({ selectedProduct }: Props) => {
  const [selectedImage, setSelectedImage] = useState("");
  const { token, currentUser, cartCount, setCartCount, loginState } =
    useContext(LoginContext);
  const toast = useToast();
  const { loading } = useContext(LoadingContext);
  const navigate = useNavigate();

  // Detect screen size
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    if (selectedProduct && selectedProduct.images?.length) {
      setSelectedImage(selectedProduct.images[0]);
    }
  }, [loading, selectedProduct]);

  return (
    <Flex flexDir={"column"} gap={4} position="relative">
      {/* Mobile View: Show Swiper */}
      {isMobile ? (
        <Box w="100%" h="350px">
          {loading ? (
            <Skeleton w="100%" h="100%" />
          ) : (
            <Carousel
              dataArray={selectedProduct.images.map((img: string) => ({ image: img }))}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          )}
        </Box>
      ) : (
        // Desktop View: Image Thumbnails + Large Image Display
        <Flex gap={5} h={"450px"}>
          {/* Thumbnails */}
          <Flex flexDir={"column"} w={"17%"} overflowY={"auto"}>
            {selectedProduct.images?.map((el: any, index: number) => (
              <Box key={index} border={"1px solid rgba(112, 112, 112, 0.3)"}>
                {loading ? (
                  <Skeleton height={"100px"} w={"100px"} />
                ) : (
                  <Image
                    src={el}
                    h={"100px"}
                    w={"100px"}
                    p={2}
                    cursor={"pointer"}
                    onMouseEnter={() => setSelectedImage(el)}
                  />
                )}
              </Box>
            ))}
          </Flex>

          {/* Main Image */}
          <VStack
            w={"88%"}
            border={"1px solid rgba(112, 112, 112, 0.3)"}
            p={7}
            mt={3}
            justify="center"
            h={"450px"} // Fixed height for main image
          >
            {loading ? (
              <Skeleton w="100%" h="100%" />
            ) : (
              <Image
                src={selectedImage}
                h="100%"
                w="auto"
                objectFit="contain"
              />
            )}
          </VStack>
        </Flex>
      )}

      {/* Sticky Buttons for Mobile */}
      <Flex
        position={isMobile ? "fixed" : "static"}
        bottom={isMobile ? "0" : "auto"}
        left="0"
        w="100%"
        bg="white"
        p={3}
        borderTop={isMobile ? "1px solid #ddd" : "none"}
        justifyContent={"space-around"}
        alignItems={"center"}
      >
        <Button
          colorScheme={"orange"}
          w={isMobile ? "48%" : "30%"}
          onClick={(e) => {
            e.stopPropagation();
            addToCart(
              selectedProduct.id,
              currentUser.id,
              token,
              cartCount,
              setCartCount,
              toast
            );
          }}
        >
          <BsCart2 fontSize={"24px"} /> &nbsp; Add to Cart
        </Button>
        <Button
          colorScheme={"whatsapp"}
          w={isMobile ? "48%" : "30%"}
          onClick={(e) => {
            e.stopPropagation();
            sessionStorage.setItem("pId", JSON.stringify(selectedProduct.id));
            buyNowClickHandler(navigate, selectedProduct.id, currentUser.id, loginState);
          }}
        >
          <AiOutlineThunderbolt fontSize={"24px"} /> &nbsp; Buy Now
        </Button>
      </Flex>
    </Flex>
  );
};

export default ImageView;
