import { Box } from "@chakra-ui/react";
import ProductsOverflowX from "../ProductsOverflowX";

type Props = {
  product: any;
};

const ProductShow = ({ product }: Props) => {
  return (
    <Box p={{ base: 4, sm: 6, md: 8 }} width="100%">
      <ProductsOverflowX product={product} />
    </Box>
  );
};

export default ProductShow;
